import React from 'react';
import { useNavigate } from 'react-router-dom';

function SignUpButton({ width = 'w-28', inNavbar = false }) {
    const navigate = useNavigate();

    const getButtonClass = () => {
        const baseClasses = `${width} px-2
            bg-primary dark:bg-primary
            text-white dark:text-white
            border border-primary dark:border-primary
            hover:bg-primary-hover dark:hover:bg-primary-hover
            transition-colors duration-300`;

        if (inNavbar) {
            // Navbar version - more compact, less rounded
            return `${baseClasses}
                py-2
                rounded-md`;
        } else {
            // Standard version - taller, more rounded, with shadow
            return `${baseClasses}
                py-3 text-base
                rounded-full shadow-md`;
        }
    };

    return (
        <button 
            className={getButtonClass()}
            onClick={() => navigate('/waitlist')}
        >
            {inNavbar ? 'Join' : 'Join waitlist'}
        </button>
    );
}

export default SignUpButton;