import React from 'react';
import SignUpButton from './SignUpButton';
import WordCarousel from './WordCarousel';
import { ArrowsPointingInIcon, ChevronDownIcon, ShieldCheckIcon, UserIcon, ChevronUpIcon, SparklesIcon } from '@heroicons/react/20/solid'
import { PaperAirplaneIcon, CurrencyEuroIcon } from '@heroicons/react/24/outline'
import ellyInterface from '../assets/elly-interface.png';
import Logo from './Logo';

const features = [
    {
        name: "Diverse.",
        description: "Elly connects to +10 of the best AI models. Combine and switch between them as you'd like. ",
        icon: ArrowsPointingInIcon,
        colors: {
            text: "text-[#9B6B9E] dark:text-[#9B6B9E]",
            bg: "bg-[#9B6B9E]/10 dark:bg-[#9B6B9E]/5",
        }
    },
    {
        name: "Smart.",
        description: "While you type, Elly can read your prompt and recommend which model to use. ",
        icon: SparklesIcon,
        colors: {
            text: "text-[#689f38] dark:text-[#689f38]",
            bg: "bg-[#689f38]/10 dark:bg-[#689f38]/5",
        }
    },
    {
        name: "Private.",
        description: "Your chats are for your eyes only. They are encrypted, anonymyzed, and not sold to third parties.",
        icon: ShieldCheckIcon,
        colors: {
            text: "text-[#2D5D7C] dark:text-[#2D5D7C]",
            bg: "bg-[#2D5D7C]/10 dark:bg-[#2D5D7C]/5",
        }
    },
    {
        name: "Yours.",
        description: "Make Elly your own. Create assistants and templates to personalize your experience.",
        icon: UserIcon,
        colors: {
            text: "text-[#FFBF00] dark:text-[#FFBF00]",
            bg: "bg-[#FFBF00]/10 dark:bg-[#FFBF00]/5",
        }
    },
    {
        name: "Affordable.",
        description: "Get started on our 'free' plan. Upgrade for less than 15 EUR per month. Cancel anytime.",
        icon: CurrencyEuroIcon,
        colors: {
            text: "text-[#E67E22] dark:text-[#E67E22]",
            bg: "bg-[#E67E22]/10 dark:bg-[#E67E22]/5",
        }
    } 
    
]

function LandingPage() {

    const scrollToSection = (sectionId) => {
        const element = document.getElementById(sectionId);
        if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
        }
    };

    return (
        <div className="min-h-screen w-full flex flex-col">            
            {/* Hero section */}
            <div id="hero" className="relative isolate px-6 pt-14 lg:px-8 min-h-screen flex items-center justify-center bg-background dark:bg-secondary-dark">
                <div className="absolute inset-x-0 -top-20 -z-10 transform-gpu overflow-hidden blur-3xl sm:-top-80">
                    <div
                        className="relative left-[calc(50%-11rem)] aspect-[1155/678] w-[20rem] -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-primary/50 to-yellow-500/30 opacity-50 sm:w-[72.1875rem]"
                        style={{
                            clipPath: 'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)'
                        }}
                    />
                </div>
                <div className="mx-auto max-w-4xl py-16 sm:py-24 md:py-32 lg:py-40">
                    <div className="text-center flex flex-col items-center ">
                        <div className="mb-8">
                            <Logo size="large" />
                        </div>
                        <div className="flex flex-col items-center">
                            <h1 className="text-4xl font-bold tracking-tight text-text dark:text-text-dark sm:text-6xl">
                                Chat with the AI friend
                            </h1>
                            <div className="flex flex-col md:flex-row items-center text-4xl font-bold tracking-tight text-text dark:text-text-dark sm:text-6xl mt-2 space-y-4">
                                <div className="whitespace-nowrap md:mr-2">designed to be</div>
                                <div className="md:ml-2 md:!mt-0">
                                    <WordCarousel />
                                </div>
                            </div>
                        </div>
                        <div className="mt-10 flex flex-col sm:flex-row items-center gap-4">
                            <SignUpButton width="w-36" inNavbar={false} />
                            <button
                                onClick={() => scrollToSection('product')}
                                className="w-auto text-text dark:text-white rounded-full hover:bg-gray-100 dark:hover:bg-gray-700 transition-colors duration-300 p-3 text-md flex items-center justify-center gap-2"
                            >
                                Learn more <ChevronDownIcon className="h-5 w-5" aria-hidden="true" />
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            <div id="product" className="overflow-hidden bg-white dark:bg-secondary-dark py-24 sm:py-32">
                <div className="mx-auto max-w-7xl px-6 lg:px-8">
                    <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-10 sm:gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-2">
                        <div className="lg:pr-8 lg:pt-4">
                            <div className="lg:max-w-lg">
                                <h2 className="text-base/7 font-semibold text-primary">How Elly works</h2>
                                <p className="mt-2 text-pretty text-4xl font-semibold tracking-tight text-text dark:text-text-dark sm:text-5xl">
                                    Just type what's on your mind
                                </p>
                                <p className="mt-6 text-lg/8 text-gray-600 dark:text-text-dark/80">
                                    ... and Elly will respond. And the best parts? Elly is:
                                </p>
                                <dl className="mt-6 max-w-xl space-y-8 text-base/7 text-gray-600 dark:text-text-dark/80 lg:max-w-none">
                                    {features.map((feature) => (
                                        <div key={feature.name} className="relative pl-16">
                                            <dt className="inline font-semibold text-text dark:text-text-dark">
                                                <div className={`absolute left-0 top-0 rounded-xl p-2 ${feature.colors.bg} shadow-md dark:shadow-md dark:border dark:border-gray-700`}>
                                                    <feature.icon 
                                                        aria-hidden="true" 
                                                        className={`size-7 ${feature.colors.text}`}
                                                    />
                                                </div>
                                                <span className={feature.colors.text}>{feature.name}</span>
                                            </dt>{' '}
                                            <dd className="inline">{feature.description}</dd>
                                        </div>
                                    ))}
                                </dl>
                                <div className="mt-10 flex flex-col sm:flex-row items-center gap-4">
                                    <SignUpButton width="w-36" inNavbar={false} />
                                    <button
                                        onClick={() => scrollToSection('purpose')}
                                        className="w-auto text-text dark:text-white rounded-full hover:bg-gray-100 dark:hover:bg-gray-700 transition-colors duration-300 p-3 text-md flex items-center justify-center gap-2"
                                    >
                                        Learn more <ChevronDownIcon className="h-5 w-5" aria-hidden="true" />
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className="flex items-center justify-center lg:justify-start">
                            <img
                                alt="Elly interface"
                                src={ellyInterface}
                                width={2432}
                                height={1442}
                                className="w-full max-w-[90vw] md:max-w-[48rem] rounded-xl shadow-xl ring-1 ring-gray-400/10 lg:w-[57rem] md:-ml-4 lg:-ml-0"
                            />
                        </div>   
                    </div>
                </div>
            </div>
            
            {/* Purpose section */}
            <div className="bg-background dark:bg-secondary-dark py-24 relative isolate overflow-hidden">
                <div className="absolute inset-x-0 -top-300 -z-10 transform-gpu overflow-hidden blur-3xl">
                    <div
                        className="relative left-[calc(50%+3rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 rotate-[180deg] bg-gradient-to-tr from-yellow-500/30 to-primary/50 opacity-50 sm:left-[calc(50%+36rem)] sm:w-[72.1875rem]"
                        style={{
                            clipPath: 'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)'
                        }}
                    />
                </div>
                <div id="purpose" className="mx-auto max-w-2xl px-6 lg:max-w-7xl lg:px-8 pt-6 flex flex-col items-center justify-center">
                    <h2 className="text-center text-base/7 font-semibold text-primary">Why Elly exists</h2>
                    <p className="mx-auto mt-4 max-w-2xl text-balance text-center text-4xl font-semibold tracking-tight pb-4 dark:text-text-dark text-text sm:text-5xl">
                        Fantastically challenging
                    </p>

                    <div className="max-w-3xl mx-auto mt-4 grid gap-4">
                        {[
                            "Generative AI has fantastic potential",
                            "But it comes with challenges, from sustainability to privacy",
                            "We started Elly to be part of the solution",
                            "We're not there yet, but progressing every day",
                            "Will you join the movement?"
                        ].map((text, index) => (
                            <div key={index} 
                                className="relative p-4 rounded-xl 
                                            bg-white dark:bg-secondary-dark
                                            backdrop-blur-sm
                                            shadow-lg hover:shadow-2xl dark:shadow-lg dark:hover:shadow-2xl transition-all duration-300
                                            border border-gray-200/60 dark:border-gray-700/60
                                            border-l-[6px] border-l-primary dark:border-l-primary
                                            pl-6">
                                <p className="text-lg/8 text-gray-600 dark:text-text-dark/80">{text}</p>
                            </div>
                        ))}
                    </div>
                    
                    <div className="mt-10 flex flex-col sm:flex-row items-center justify-center gap-4">
                        <SignUpButton width="w-36" inNavbar={false} />
                        <a
                            href="mailto:hi@ask-elly.co"
                            className="w-full sm:w-auto text-text dark:text-white rounded-full hover:bg-gray-100 dark:hover:bg-gray-700 transition-colors duration-300 p-3 text-md flex items-center justify-center gap-2"
                        >
                            Get in touch <PaperAirplaneIcon className="h-5 w-5" aria-hidden="true" />
                        </a>
                        <button
                            onClick={() => scrollToSection('hero')}
                            className="w-full sm:w-auto text-text dark:text-white rounded-full hover:bg-gray-100 dark:hover:bg-gray-700 transition-colors duration-300 p-3 text-md flex items-center justify-center gap-2"
                        >
                            Back to top <ChevronUpIcon className="h-5 w-5" aria-hidden="true" />
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default LandingPage;