import React, { useState, useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import TemplatesPopup from './TemplatesPopup';
import { toast } from 'react-toastify';
import { API_URL } from '../config/api';
import Tooltip from './Tooltip';

function Templates({ user, selectedTemplate, setSelectedTemplate, setSelectedModel }) {
    const { isAuthenticated } = useAuth0();
    const [showTemplatesPopup, setShowTemplatesPopup] = useState(false);
    const [showDesignTemplatePopup, setShowDesignTemplatePopup] = useState(false);
    const [templates, setTemplates] = useState([]);
    const [editingTemplate, setEditingTemplate] = useState(null);
    const [deletingTemplate, setDeletingTemplate] = useState(null);
    const [showDeleteTemplatePopup, setShowDeleteTemplatePopup] = useState(false);

    useEffect(() => {
        const fetchTemplates = async () => {
            if (!isAuthenticated || !user) {
                console.log('User not authenticated or user data not available');
                return;
            }
            
            try {
                const response = await fetch(`${API_URL}/templates/${user.sub}`);
                const data = await response.json();
                setTemplates(data.templates);
            } catch (error) {
                console.error('Error fetching templates:', error);
                toast.error('Oops, something went wrong. Please try again.');
            }
        };

        fetchTemplates();
    }, [isAuthenticated, user]);

    const getCategoryColor = (category) => {
        const categoryColors = {
            'Green': '#7cb342',
            'Blue': '#90caf9',
            'Red': '#ef9a9a',
            'Yellow': '#fff59d',
            'Purple': '#ce93d8',
            'Orange': '#ffcc80',
            'Teal': '#80cbc4'
        };
        return categoryColors[category] || '#7cb342';
    };

    const handleTemplateSubmit = async (templateTitle, templatePrompt, templateCategory, templateModel, templateId) => {
        try {
            const url = templateId 
                ? `${API_URL}/templates/${templateId}`
                : `${API_URL}/templates`;
            
            const method = templateId ? 'PUT' : 'POST';

            const response = await fetch(url, {
                method: method,
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ 
                    userId: user.sub, 
                    title: templateTitle, 
                    prompt: templatePrompt,
                    category: templateCategory,
                    model: templateModel
                }),
            });

            if (!response.ok) {
                throw new Error(templateId ? 'Failed to update template' : 'Failed to create template');
            }
        
            // Fetch updated templates
            const fetchUpdatedTemplates = await fetch(`${API_URL}/templates/${user.sub}`);
            const updatedTemplates = await fetchUpdatedTemplates.json();
            setTemplates(updatedTemplates.templates);

            toast.success(templateId ? 'Template updated' : 'Template created');
            
        } catch (error) {
            console.error('Error handling template:', error);
            toast.error('Oops, something went wrong. Please try again.');
        }

        setShowDesignTemplatePopup(false);
        setEditingTemplate(null);
    };

    const handleTemplateClick = (template) => {
        if (template === null) {
            setSelectedTemplate(null);
            toast.success("Selected 'No template'");
        } else {
            setSelectedTemplate(template);
            if (template.model !== 'None') {
                setSelectedModel(template.model);
            }
            toast.success(`${template.decrypted_title} selected`);
        }
        setShowTemplatesPopup(false);
    };

    const handleEditClick = (e, template) => {
        e.stopPropagation();
        setEditingTemplate(template);
        setShowDesignTemplatePopup(true);
    };

    const handleDeleteClick = (e, template) => {
        e.stopPropagation();
        setDeletingTemplate(template);
        setShowDeleteTemplatePopup(true);
    };

    const handleDeleteTemplate = async () => {
        try {
            const response = await fetch(`${API_URL}/templates/delete/${deletingTemplate.template_id}`, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            if (!response.ok) {
                throw new Error('Failed to delete template');
            }

            const fetchUpdatedTemplates = await fetch(`${API_URL}/templates/${user.sub}`);
            const updatedTemplates = await fetchUpdatedTemplates.json();
            setTemplates(updatedTemplates.templates);

            toast.success('Template deleted');
        } catch (error) {
            console.error('Error deleting template:', error);
            toast.error('Failed to delete template');
        }
        setShowDeleteTemplatePopup(false);
        setDeletingTemplate(null);
    };

    return (
        <div className="flex flex-col items-center justify-center">
            <Tooltip content="Templates: Using the same prompts often? Create a template to avoid retyping every time" width="w-56">
                <button 
                    className={`flex items-center h-9 space-x-1 px-4 py-2 bg-background dark:bg-background-dark ${
                        selectedTemplate
                            ? 'text-text dark:text-text-dark' 
                            : 'text-gray-400 dark:text-gray-500'
                    } text-sm rounded-2xl hover:bg-hover dark:hover:bg-hover-dark transition-colors duration-300`}
                    onClick={() => setShowTemplatesPopup(true)}
                >
                    <svg 
                        xmlns="http://www.w3.org/2000/svg" 
                        width="16" 
                        height="16" 
                        viewBox="0 0 24 24" 
                        fill="none" 
                        stroke={selectedTemplate ? 'currentColor' : '#9CA3AF'} 
                        strokeWidth="2" 
                        strokeLinecap="round" 
                        strokeLinejoin="round"
                    >
                        <rect x="8" y="8" width="14" height="14" rx="2" ry="2" />
                        <path d="M3 16V4a2 2 0 0 1 2-2h12l2 2" />
                    </svg>
                </button>
            </Tooltip>
            
            {showTemplatesPopup && (
                <TemplatesPopup
                    onClose={() => setShowTemplatesPopup(false)}
                    templates={templates}
                    handleTemplateClick={handleTemplateClick}
                    handleEditClick={handleEditClick}
                    showDesignTemplatePopup={showDesignTemplatePopup}
                    setShowDesignTemplatePopup={setShowDesignTemplatePopup}
                    handleTemplateSubmit={handleTemplateSubmit}
                    editingTemplate={editingTemplate}
                    setEditingTemplate={setEditingTemplate}
                    handleDeleteClick={handleDeleteClick}
                    showDeleteTemplatePopup={showDeleteTemplatePopup}
                    setShowDeleteTemplatePopup={setShowDeleteTemplatePopup}
                    handleDeleteTemplate={handleDeleteTemplate}
                    deletingTemplate={deletingTemplate}
                    getCategoryColor={getCategoryColor}
                    selectedTemplate={selectedTemplate}
                />
            )}
        </div>
    );
}

export default Templates;