import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { toast } from 'react-toastify';
import { API_URL } from '../config/api';
import { StarIcon } from '@heroicons/react/24/outline';
import { StarIcon as StarIconSolid } from '@heroicons/react/24/solid';

const ChatSearchPopup = ({ onClose, onSelect, user, setConversations }) => {
    const { isAuthenticated } = useAuth0();
    const [searchTerm, setSearchTerm] = useState('');
    const [conversationsForSearch, setConversationsForSearch] = useState([]);
    const [filteredConversations, setFilteredConversations] = useState([]);
    const [showStarredOnly, setShowStarredOnly] = useState(false);
    const inputRef = useRef(null);

    const fetchConversations = useCallback(async () => {
        try {
            if (!isAuthenticated || !user) {
                console.log('User not authenticated or user data not available');
                return;
            }

            const response = await fetch(`${API_URL}/search/${user.sub}`);
            
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            
            const data = await response.json();

            setConversationsForSearch(data);
        } catch (error) {
            console.error('Error fetching conversations:', error);
        }
    }, [isAuthenticated, user]);

    useEffect(() => {
        inputRef.current?.focus();
        fetchConversations();
    }, [fetchConversations]);

    const handleSubmit = (e) => {
        e.preventDefault();
        if (filteredConversations.length > 0) {
            onSelect(filteredConversations[0].conversation_id);
        }
    };

    useEffect(() => {
        const filtered = conversationsForSearch
          .map(conv => ({
            ...conv,
            score: calculateRelevanceScore(conv, searchTerm)
          }))
          .sort((a, b) => b.score - a.score)
          .filter(conv => conv.score > 0)
          .filter(conv => !showStarredOnly || conv.starred);
    
        setFilteredConversations(filtered);
    }, [searchTerm, conversationsForSearch, showStarredOnly]);
    
    const calculateRelevanceScore = (conversation, term) => {
        if (!term) return 1;
        
        const searchTermLower = term.toLowerCase();
        const titleLower = conversation.decrypted_title.toLowerCase();
        const memoryValue = conversation.decrypted_memory_value;
        
        let score = 0;
    
        if (titleLower.includes(searchTermLower)) {
          score += 5;
        }

        if (memoryValue) {
          const chatHistory = memoryValue.toLowerCase();
          if (chatHistory.includes(searchTermLower)) {
            score += 3;
          }
        }

        if (titleLower === searchTermLower) {
          score += 2;
        }
    
        return score;
    };

    const formatDate = (dateString) => {
        const now = new Date();
        const updatedDate = new Date(dateString);
        const diffTime = Math.abs(now - updatedDate);
        const diffDays = Math.floor(diffTime / (1000 * 60 * 60 * 24));
        
        if (diffDays === 0) {
            return 'Today';
        } else {
            return `${diffDays} day${diffDays > 1 ? 's' : ''}`;
        }
    };

    const handleStarClick = async (e, conversationId) => {
        e.stopPropagation();
        try {
            const response = await fetch(`${API_URL}/conversations/${conversationId}/star`, {
                method: 'PATCH',
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            setFilteredConversations(prevConversations =>
                prevConversations.map(conv =>
                    conv.conversation_id === conversationId
                        ? { ...conv, starred: !conv.starred }
                        : conv
                )
            );

            setConversations(prevConversations =>
                prevConversations.map(conv =>
                    conv.conversation_id === conversationId
                        ? { ...conv, starred: !conv.starred }
                        : conv
                )
            );

        } catch (error) {
            console.error('Error starring conversation:', error);
            toast.error('Oops, something went wrong. Please try again.');
        }
    };

    return (
        <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-10 backdrop-blur-sm p-4" onClick={onClose}>
            <div className="bg-white dark:bg-gray-800 rounded-lg p-4 sm:p-6 w-full max-w-sm sm:max-w-xl" onClick={e => e.stopPropagation()}>
                <div className="flex justify-between items-center mb-4">
                    <h2 className="text-lg sm:text-xl font-bold text-text dark:text-text-dark">Type to start searching</h2>
                    <button
                        onClick={() => setShowStarredOnly(!showStarredOnly)}
                        className="px-3 py-1 rounded-md text-sm bg-background dark:bg-gray-700 text-gray-800 dark:text-gray-200 border border-border dark:border-border-dark"
                    >
                        {showStarredOnly ? (
                            <StarIconSolid className="w-5 h-5 text-yellow-400" />
                        ) : (
                            <StarIcon className="w-5 h-5 text-gray-300 dark:text-gray-400" />
                        )}
                    </button>
                </div>
                
                <form onSubmit={handleSubmit} className="flex flex-col space-y-4">
                    <input
                        ref={inputRef}
                        type="text"
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                        placeholder="Search for conversations..."
                        className="w-full px-3 py-2 border rounded-md dark:bg-gray-700 dark:border-gray-600 dark:text-white focus:outline-none focus:ring-2 focus:ring-primary lg:focus:ring-0"
                    />
                    <div className="h-96 overflow-y-auto border border-border dark:border-border-dark rounded-md">
                        {filteredConversations.map((conversation) => (
                            <div 
                                key={conversation.conversation_id} 
                                className="p-2 hover:bg-gray-100 dark:hover:bg-gray-700 cursor-pointer rounded flex items-center"
                            >
                                <div 
                                    className="flex-1 min-w-0 flex justify-between items-center"
                                    onClick={() => {
                                        onSelect(conversation.conversation_id);
                                        onClose();
                                    }}
                                >
                                    <span className="text-text dark:text-text-dark text-sm truncate">
                                        {conversation.decrypted_title}
                                    </span>
                                    <span className="hidden sm:block text-gray-400 dark:text-gray-400 text-xs flex-shrink-0 ml-2">
                                        {formatDate(conversation.updated_at)}
                                    </span>
                                </div>
                                <button
                                    onClick={(e) => handleStarClick(e, conversation.conversation_id)}
                                    className="flex-shrink-0 focus:outline-none hover:bg-white dark:hover:bg-gray-500 rounded-lg p-1 ml-2"
                                >
                                    {conversation.starred ? (
                                        <StarIconSolid className="w-4 h-4 text-yellow-400" />
                                    ) : (
                                        <StarIcon className="w-4 h-4 text-gray-300 dark:text-gray-400" />
                                    )}
                                </button>
                            </div>
                        ))}
                    </div>
                    <div className="flex flex-col sm:flex-row justify-between space-y-2 sm:space-y-0 sm:space-x-2">
                        <button
                            type="button"
                            onClick={onClose}
                            className="w-full px-4 py-2 bg-gray-200 dark:bg-gray-700 text-gray-800 dark:text-gray-200 rounded hover:bg-gray-300 dark:hover:bg-gray-600 transition-colors"
                        >
                            Cancel
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default ChatSearchPopup;