import React from 'react';
import { 
    TrophyIcon, 
    BoltIcon,   
    CurrencyEuroIcon,
    PhotoIcon,
    GlobeAltIcon
} from '@heroicons/react/24/outline'
import Tooltip from './Tooltip';

const ModelButton = ({ model, selectedModel, setSelectedModel, isPopup = false, disabled}) => (
    <div 
        className={`flex ${isPopup ? 'flex-col' : 'items-center justify-center'} bg-none ${
            !disabled ? 'hover:bg-hover dark:hover:bg-hover-dark cursor-pointer' : 'opacity-50 cursor-not-allowed'
        } border border-gray-200 dark:border-gray-700 ${isPopup ? 'p-5 my-3' : 'p-3'} ${
            isPopup ? 'rounded-lg' : 'rounded-2xl'} w-full ${isPopup ? 'h-auto' : 'h-9'} ${
            selectedModel === model.id ? 'border-primary dark:border-primary text-primary dark:text-primary' : ''
        } transition-colors duration-300`}
        onClick={() => !disabled && setSelectedModel(model.id)}
    >
        <div className="flex items-center justify-between">
            <div className="flex items-center" onClick={(e) => {
                e.stopPropagation();
                !disabled && setSelectedModel(model.id);
            }}>
                <img className="h-4 mr-2 rounded-sm" src={model.logo} alt={`${model.name} Logo`} />
                <p className={`text-sm flex ${isPopup ? 'font-semibold' : 'font-normal'}`}>{model.name}</p>
            </div>
            {isPopup && (
            <>
                <div className="flex text-xs mt-1 text-gray-500 dark:text-gray-500 items-center">
                    <Tooltip content="Strength" width="w-auto">
                        <div className="hidden lg:flex items-center">
                            <TrophyIcon className="w-3 h-3 mr-1" />
                            {model.strength}
                        </div>
                    </Tooltip>
                    <span className="hidden lg:flex mx-2">•</span>
                    <Tooltip content="Speed" width="w-auto">
                        <div className="hidden sm:flex items-center">
                            <BoltIcon className={`w-3 h-3 mr-1 ${
                                model.speed >= 9 ? 'text-emerald-500' : 
                                model.speed >= 6 ? 'text-amber-500' : 
                                model.speed >= 3 ? 'text-orange-500' : 
                                'text-rose-800'
                            }`} />
                            {(() => {
                                const speed = model.speed;
                                if (speed >= 9) return 'Rapid';
                                if (speed >= 6) return 'Fast';
                                if (speed >= 3) return 'Moderate';
                                return 'Slow';
                            })()}
                        </div>
                    </Tooltip>
                    <span className="hidden md:flex mx-1 sm:mx-2">•</span>
                    <Tooltip content="Cost in credits" width="w-28">
                        <div className="hidden md:flex items-center">
                            <CurrencyEuroIcon className={`w-3 h-3 mr-1 ${
                                model.cost < 10 ? 'text-emerald-500' : 
                                model.cost < 20 ? 'text-amber-500' : 
                                model.cost < 30 ? 'text-orange-500' : 
                                'text-rose-800'
                            }`} />
                            {model.cost}
                        </div>
                    </Tooltip>
                    <span className="hidden sm:flex mx-1 sm:mx-2">•</span>
                    <Tooltip content="Image understanding" width="w-40">
                        <div className="flex items-center">
                            <PhotoIcon className={`w-3 h-3 mr-1 ${
                                model.vision ? 'text-emerald-500' : 'text-rose-800'
                            }`} />
                            {model.vision ? 'Yes' : 'No'}
                        </div>
                    </Tooltip>
                    <span className="mx-1 sm:mx-2">•</span>
                    <Tooltip content="Web search" width="w-24">
                        <div className="flex items-center">
                            <GlobeAltIcon className={`w-3 h-3 mr-1 ${
                                model.search ? 'text-emerald-500' : 'text-rose-800'
                            }`} />
                            {model.search ? 'Yes' : 'No'}
                        </div>
                    </Tooltip>
                </div>
            </>
        )}
        </div>
        
    </div>
);

export default ModelButton;