import { formatMessages } from './utils/messageUtils';
import { getModelInfo } from './components/ModelData';
import { API_URL } from './config/api';
import { toast } from 'react-toastify';

const DAILY_LIMITS = {
  free: 70,
  plus: 600,
  pro: 1500,
  admin: 3000
};
//   try {
//     setStreaming(true);
//     setStreamedResponse('');

//     const response = await fetch(`${API_URL}/messages`, {
//       method: 'POST',
//       headers: {
//         'Content-Type': 'application/json'
//       },
//       body: JSON.stringify({
//         model: selectedModel,
//         messages: messages,
//         message: message,
//         stream: true,
//         user_id: user?.sub,
//         username: user?.name,
//         conversationId: conversationId,
//         assistant: selectedAssistant,
//         magicMode: magicMode
//       })
//     });

//     if (response.status === 202) {
//       const data = await response.json();
//       setStreamedResponse(`Your request is taking longer than expected. It has been queued and will be processed shortly.`);
      
//       // Implement polling mechanism
//       const MAX_RETRIES = 12; // 1 minute maximum (12 * 5 seconds)
//       let retryCount = 0;

//       const pollQueue = async () => {
//         if (retryCount >= MAX_RETRIES) {
//           setStreamedResponse(`"Oops! It seems like things are a bit busy right now. We’re sorry for the wait. Please try again.`);
//           setStreaming(false);
//           return;
//         }

//         const pollResponse = await fetch(`${API_URL}/queue/${data.queueId}`);
//         const pollData = await pollResponse.json();

//         if (pollResponse.status === 200) {
//           // Request completed
//           setStreamedResponse(pollData.response);
//           setStreaming(false);
//         } else if (pollResponse.status === 202) {
//           // Still in queue, update message and continue polling
//           setStreamedResponse(`Your request is still being processed. Please wait... (${retryCount + 1}/${MAX_RETRIES})`);
//           retryCount++;
//           setTimeout(pollQueue, 5000); // Poll every 5 seconds
//         } else {
//           // Error occurred
//           setStreamedResponse(`An error occurred while processing your request. Please try again.`);
//           setStreaming(false);
//         }
//       };

//       setTimeout(pollQueue, 5000); // Start polling after 5 seconds
//     } else if (response.ok) {

//       const reader = response.body.getReader();
//       const decoder = new TextDecoder('utf-8');
//       let result = '';
      
//       while (true) {
//         const { done, value } = await reader.read();
//         if (done) break;
//         result += decoder.decode(value, { stream: true });
//         const currentResult = result;
//         setStreamedResponse(() => currentResult);
//       }
//       setStreaming(false);

//       const newConversationId = response.headers.get('X-Conversation-Id');

//       // Introduce a short timeout to ensure all updates are processed
//       await new Promise(resolve => setTimeout(resolve, 30));

//       // Update messages
//       const messagesFetch = await fetch(`${API_URL}/messages/${newConversationId}`);

//       if (!messagesFetch.ok) {
//         throw new Error(`HTTP error! status: ${messagesFetch.status}`);
//       }

//       const updatedMessages = await messagesFetch.json();
//       const formattedMessages = formatMessages(updatedMessages);
//       setMessages(formattedMessages);

//       // Update conversations
//       const conversations = await fetch(`${API_URL}/conversations/${user.sub}`);
      
//       if (!conversations.ok) {
//         throw new Error(`HTTP error! status: ${conversations.status}`);
//       }

//       const data = await conversations.json();
//       setConversations(data);

//       // Subtract credits based on model cost
//       const selectedModelCost = getModelInfo().find(model => model.versions.includes(selectedModel))?.cost || 0;
//       const subtractCreditsResponse = await fetch(`${API_URL}/user/subtract-credits`, {
//         method: 'POST',
//         headers: {
//           'Content-Type': 'application/json'
//         },
//         body: JSON.stringify({
//           userId: user.sub,
//           modelCost: selectedModelCost
//         })
//       });

//       if (!subtractCreditsResponse.ok) {
//         console.error('Failed to subtract credits');
//       }

//       const userInfoResponse = await fetch(`${API_URL}/user/info/${user.sub}`);

//       if (userInfoResponse.ok) {
//         const userInfoData = await userInfoResponse.json();
//         const newBalance = userInfoData.userInfo.points_balance;
//         const previousBalance = newBalance + selectedModelCost;
//         setCreditsBalance(newBalance);

//         // Calculate percentage of daily limit remaining
//         const dailyLimit = DAILY_LIMITS[userInfoData.userInfo.role] || DAILY_LIMITS.free;
//         const previousPercentage = (previousBalance / dailyLimit) * 100;
//         const newPercentage = (newBalance / dailyLimit) * 100;
        
//         // Show warning only when crossing the 25% threshold
//         if (previousPercentage > 25 && newPercentage <= 25) {
//           toast.info('You have less than 25% of your daily credits left. Using cheaper models lets you send more messages before reaching your limit', {
//             autoClose: 15000,
//             toastId: 'credits-alert'
//           });
//         }

//       } else {
//         console.error('Failed to fetch updated credits balance');
//       }

//       return { conversationId: newConversationId, updatedMessages: messages };

//     } else {
//       throw new Error(`HTTP error! status: ${response.status}`);
//     }

//   } catch (error) {
//     console.error('askAi - Error:', error);
//     setStreaming(false);
//     setStreamedResponse(`An error occurred: ${error.message}. Please try again.`);
//     throw new Error(`An error occurred: ${error.message}. Please try again.`);
//   }
// };

export const askAi = async (selectedModel, messages, setMessages, message, setStreamedResponse, setStreaming, user, conversationId, setConversations, setCreditsBalance, selectedAssistant, magicMode, magicModeSetting, setMagicModeSetting) => {
  try {
    setStreaming(true);
    setStreamedResponse('');

    if (magicMode !== magicModeSetting) {
      try {
          const response = await fetch(`${API_URL}/user/settings/magic-mode/update`, {
              method: 'PUT',
              headers: {
                  'Content-Type': 'application/json'
              },
              body: JSON.stringify({
                  userId: user.sub,
                  magicMode
              })
          });
          
          if (response.ok) {
              setMagicModeSetting(magicMode);
          }
      } catch (error) {
          console.error('Error updating magic mode setting:', error);
      }
    }
    
    const response = await fetch(`${API_URL}/messages`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        model: selectedModel,
        messages: messages,
        message: message,
        stream: true,
        user_id: user?.sub,
        username: user?.name,
        conversationId: conversationId,
        assistant: selectedAssistant,
        magicMode: magicMode
      })
    });

    if (response.status === 202) {
      const data = await response.json();
      setStreamedResponse(`Your request is taking longer than expected. It has been queued and will be processed shortly.`);
      
      // Implement polling mechanism
      const MAX_RETRIES = 12; // 1 minute maximum (12 * 5 seconds)
      let retryCount = 0;

      const pollQueue = async () => {
        if (retryCount >= MAX_RETRIES) {
          setStreamedResponse(`"Oops! It seems like things are a bit busy right now. We're sorry for the wait. Please try again.`);
          setStreaming(false);
          return;
        }

        const pollResponse = await fetch(`${API_URL}/queue/${data.queueId}`);
        const pollData = await pollResponse.json();

        if (pollResponse.status === 200) {
          // Request completed
          setStreamedResponse(pollData.response);
          setStreaming(false);
        } else if (pollResponse.status === 202) {
          // Still in queue, update message and continue polling
          setStreamedResponse(`Your request is still being processed. Please wait... (${retryCount + 1}/${MAX_RETRIES})`);
          retryCount++;
          setTimeout(pollQueue, 5000); // Poll every 5 seconds
        } else {
          // Error occurred
          setStreamedResponse(`An error occurred while processing your request. Please try again.`);
          setStreaming(false);
        }
      };

      setTimeout(pollQueue, 5000); // Start polling after 5 seconds
    } else if (response.ok) {
      const newConversationId = response.headers.get('X-Conversation-Id');

      if (selectedModel === 'perplexica') {
        const data = await response.json();
        const newMessage = {
          role: 'assistant',
          content: data.message, // Pass the raw message
          model: selectedModel
        };
        
        setMessages(prev => [...prev, newMessage]);
        setStreamedResponse(data.message);
        setStreaming(false);
      } else {
        const reader = response.body.getReader();
        const decoder = new TextDecoder('utf-8');
        let result = '';
        
        while (true) {
          const { done, value } = await reader.read();
          if (done) break;
          result += decoder.decode(value, { stream: true });
          const currentResult = result;
          setStreamedResponse(() => currentResult);
        }
        setStreaming(false);
      }

      // Introduce a short timeout to ensure all updates are processed
      await new Promise(resolve => setTimeout(resolve, 30));

      // Update messages
      const messagesFetch = await fetch(`${API_URL}/messages/${newConversationId}`);

      if (!messagesFetch.ok) {
        throw new Error(`HTTP error! status: ${messagesFetch.status}`);
      }

      const updatedMessages = await messagesFetch.json();
      const formattedMessages = formatMessages(updatedMessages);
      setMessages(formattedMessages);

      // Update conversations
      const conversations = await fetch(`${API_URL}/conversations/${user.sub}`);
      
      if (!conversations.ok) {
        throw new Error(`HTTP error! status: ${conversations.status}`);
      }

      const data = await conversations.json();
      setConversations(data);

      // Subtract credits based on model cost
      const selectedModelCost = getModelInfo().find(model => model.versions.includes(selectedModel))?.cost || 0;
      const subtractCreditsResponse = await fetch(`${API_URL}/user/subtract-credits`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          userId: user.sub,
          modelCost: selectedModelCost
        })
      });

      if (!subtractCreditsResponse.ok) {
        console.error('Failed to subtract credits');
      }

      const userInfoResponse = await fetch(`${API_URL}/user/info/${user.sub}`);

      if (userInfoResponse.ok) {
        const userInfoData = await userInfoResponse.json();
        const newBalance = userInfoData.userInfo.points_balance;
        const previousBalance = newBalance + selectedModelCost;
        setCreditsBalance(newBalance);

        // Calculate percentage of daily limit remaining
        const dailyLimit = DAILY_LIMITS[userInfoData.userInfo.role] || DAILY_LIMITS.free;
        const previousPercentage = (previousBalance / dailyLimit) * 100;
        const newPercentage = (newBalance / dailyLimit) * 100;
        
        // Show warning only when crossing the 25% threshold
        if (previousPercentage > 25 && newPercentage <= 25) {
          toast.info('You have less than 25% of your daily credits left. Using cheaper models lets you send more messages before reaching your limit', {
            autoClose: 15000,
            toastId: 'credits-alert'
          });
        }
      } else {
        console.error('Failed to fetch updated credits balance');
      }

      return { conversationId: newConversationId, updatedMessages: messages };
    } else {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
  } catch (error) {
    console.error('askAi - Error:', error);
    setStreaming(false);
    setStreamedResponse(`An error occurred: ${error.message}. Please try again.`);
    throw new Error(`An error occurred: ${error.message}. Please try again.`);
  }
};