import React, { useState, useEffect } from 'react';

const baseWords = [
  'Thinking',
  'Analyzing',
  'Considering',
  'Processing',
  'Pondering'
];

const perplexicaWords = [
  'Searching - please be patient',
  'Browsing through websites',
  'Drafting response - hold on',
  'Reading sources - please wait',
  'Synthesizing search information'
];

const WORD_CHANGE_INTERVAL = 3000; // 2 seconds
const LONG_WAIT_THRESHOLD = 12000; // 10 seconds

const BufferingAnimation = ({ darkMode, model }) => {
  const [index, setIndex] = useState(0);
  const [isLongWait, setIsLongWait] = useState(false);

  const isPerplexica = model?.startsWith('perplexica-');
  const words = isPerplexica ? perplexicaWords : baseWords;

  useEffect(() => {
    const longWaitTimer = setTimeout(() => {
      setIsLongWait(true);
    }, LONG_WAIT_THRESHOLD);
    
    const wordTimer = setInterval(() => {
      setIndex(prev => (prev + 1) % words.length);
    }, WORD_CHANGE_INTERVAL);

    return () => {
      clearTimeout(longWaitTimer);
      clearInterval(wordTimer);
    };
  }, [words.length]);

  const currentWord = isLongWait && !isPerplexica
    ? `Still ${baseWords[index].toLowerCase()}`
    : words[index];

  return (
    <div className={`relative h-8 ${isPerplexica ? 'min-w-[20rem]' : 'min-w-[12rem]'}`}>
      <div
        className={`absolute inset-0 bg-secondary dark:bg-secondary-dark
          text-text dark:text-text-dark font-light px-4 rounded-full
          before:absolute before:inset-0 before:bg-gradient-to-r
          before:from-transparent before:via-white/10 before:to-transparent
          overflow-hidden inline-flex items-center justify-center w-fit`}
      >
        {currentWord}
      </div>
    </div>
  );
};

export default BufferingAnimation;