import React, { useState, useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import AuthenticatedApp from './components/AuthenticatedApp';
import WebsiteLayout from './components/WebsiteLayout';
import LandingPage from './components/LandingPage';
import WaitlistForm from './components/WaitlistForm';
import PrivacyPolicy from './components/PrivacyPolicy';
import CookiePolicy from './components/CookiePolicy';
import TermsOfUse from './components/TermsOfUse';

function App() {
    const { isAuthenticated } = useAuth0();
    const [darkMode, setDarkMode] = useState(() => localStorage.getItem('darkMode') === 'true');

    const toggleDarkMode = () => {
      setDarkMode(prev => !prev);
    };

    useEffect(() => {
      if (darkMode) {
          document.documentElement.classList.add('dark');
      } else {
          document.documentElement.classList.remove('dark');
      }
      localStorage.setItem('darkMode', darkMode);
    }, [darkMode]);

    // Public routes
    if (!isAuthenticated) {
        return (
            <BrowserRouter>
                <div className="app-container">
                    <Routes>
                        <Route path="/waitlist" element={
                            <WebsiteLayout darkMode={darkMode} toggleDarkMode={toggleDarkMode}>
                                <WaitlistForm />
                            </WebsiteLayout>
                        } />
                        <Route path="/terms" element={
                            <WebsiteLayout darkMode={darkMode} toggleDarkMode={toggleDarkMode}>
                                <TermsOfUse />
                            </WebsiteLayout>
                        } />
                        <Route path="/privacy" element={
                            <WebsiteLayout darkMode={darkMode} toggleDarkMode={toggleDarkMode}>
                                <PrivacyPolicy />
                            </WebsiteLayout>
                        } />
                        <Route path="/cookies" element={
                            <WebsiteLayout darkMode={darkMode} toggleDarkMode={toggleDarkMode}>
                                <CookiePolicy />
                            </WebsiteLayout>
                        } />
                        <Route path="/*" element={
                            <WebsiteLayout darkMode={darkMode} toggleDarkMode={toggleDarkMode}>
                                <LandingPage />
                            </WebsiteLayout>
                        } />
                    </Routes>
                    <ToastContainer
                        position="top-right"
                        theme={darkMode ? 'dark' : 'light'}
                        toastClassName={`${darkMode ? 'border border-border-dark' : ''}`}
                        autoClose={5000}
                        hideProgressBar={false}
                        newestOnTop={true}
                        closeOnClick={false}
                        rtl={false}
                        pauseOnFocusLoss={true}
                        draggable
                        pauseOnHover
                    />
                </div>
            </BrowserRouter>
        );
    }

    // Auth routes - all auth state is only initialized here
    return <AuthenticatedApp darkMode={darkMode} toggleDarkMode={toggleDarkMode} />;
}

export default App;