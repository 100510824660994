import React, { useState, useRef, useEffect, useCallback } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import AuthButton from "./AuthButton";
import SettingsPopup from "./SettingsPopup";
// import DarkModeToggle from "./DarkModeToggle";
import { API_URL } from '../config/api';

const Profile = ({ darkMode, toggleDarkMode, creditsBalance, setCreditsBalance, addSystemPrompt, setAddSystemPrompt, systemPrompt, setSystemPrompt }) => {
  const { user, isAuthenticated, isLoading } = useAuth0();
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [settingsVisible, setSettingsVisible] = useState(false);
  const [userRole, setUserRole] = useState("");
  const dropdownRef = useRef(null);

  const fetchCreditsBalance = useCallback(async () => {
    if (!isAuthenticated || !user) return;
    try {
      const response = await fetch(`${API_URL}/user/info/${user.sub}`);
      if (!response.ok) {
        throw new Error('Failed to fetch user info');
      }
      const data = await response.json();
      setCreditsBalance(data.userInfo.points_balance);
      setUserRole(data.userInfo.role.charAt(0).toUpperCase() + data.userInfo.role.slice(1));
    } catch (error) {
      console.error('Error fetching user info:', error);
    }
  }, [isAuthenticated, user, setCreditsBalance]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (isAuthenticated && user) {
        fetchCreditsBalance();
      }

      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setDropdownVisible(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };

  }, [isAuthenticated, user, fetchCreditsBalance]);

  const toggleDropdown = (event) => {
    event.stopPropagation();
    setDropdownVisible(!dropdownVisible);
  };

  if (isLoading) {
    return <div>Loading ...</div>;
  }

  return (
    isAuthenticated && (
      <div className="relative flex items-center">
        <button 

          className="w-7 h-7 rounded-full bg-transparent border-none p-0 cursor-pointer" 
          onClick={toggleDropdown}
        >
          <img 
            className="w-7 h-7 rounded-full" 
            src={user.picture} 
            alt={user.name} 
          />
        </button>
        {dropdownVisible && (
          <div 
            ref={dropdownRef}
            className="absolute top-full right-0 mt-1 bg-white dark:bg-background-dark border border-gray-300 dark:border-border-dark shadow-md rounded-2xl z-10 p-5 w-64"
          >
            <p className="text-left font-semibold mb-1">{user.name}</p>
            <p className="text-left text-gray-500 dark:text-gray-400 text-sm mb-3">{user.email}</p>
            <hr className="my-3" />
            <div className="flex justify-between mb-2 text-sm">
              <span className="font-semibold">Membership</span>
              <span>{userRole}</span>
            </div>
            <div className="flex justify-between mb-3 text-sm">
              <span className="font-semibold">Credits</span>
              <span>{creditsBalance}</span>
            </div>
            <hr className="my-3" />
            <button
              onClick={() => setSettingsVisible(true)}
              className="w-full h-10 mb-2 bg-white dark:bg-background-dark text-text dark:text-text-dark border border-border dark:border-border-dark p-1 rounded-md hover:bg-gray-100 dark:hover:bg-gray-700 transition-colors duration-300 text-sm"
            >
              Settings
            </button>
            <AuthButton width="w-full" />
          </div>
        )}
        {settingsVisible && (
          <SettingsPopup 
            onClose={() => setSettingsVisible(false)} 
            darkMode={darkMode} 
            toggleDarkMode={toggleDarkMode} 
            addSystemPrompt={addSystemPrompt}
            setAddSystemPrompt={setAddSystemPrompt}
            systemPrompt={systemPrompt}
            setSystemPrompt={setSystemPrompt}
          />
        )}
      </div>
    )
  );
};

export default Profile;