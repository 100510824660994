import React, { useState, useEffect } from 'react';
import { SparklesIcon, ShieldCheckIcon, ArrowsPointingInIcon, UserIcon, CurrencyEuroIcon } from '@heroicons/react/24/outline';

const words = [
    { 
        text: 'diverse', 
        icon: ArrowsPointingInIcon, 
        bgColor: 'bg-[#9B6B9E]/10 dark:bg-[#9B6B9E]/5',
        textColor: 'text-[#9B6B9E] dark:text-[#9B6B9E]',
    },
    { 
        text: 'smart', 
        icon: SparklesIcon,
        bgColor: 'bg-[#689f38]/10 dark:bg-[#689f38]/5',
        textColor: 'text-[#689f38] dark:text-[#689f38]',
    },
    { 
        text: 'private', 
        icon: ShieldCheckIcon, 
        bgColor: 'bg-[#2D5D7C]/10 dark:bg-[#2D5D7C]/5',
        textColor: 'text-[#2D5D7C] dark:text-[#2D5D7C]',
    },
    { 
        text: 'yours', 
        icon: UserIcon, 
        bgColor: 'bg-[#FFBF00]/10 dark:bg-[#FFBF00]/5',  
        textColor: 'text-[#FFBF00] dark:text-[#FFBF00]',
    },
    { 
        text: 'affordable', 
        icon: CurrencyEuroIcon, 
        bgColor: 'bg-[#E67E22]/10 dark:bg-[#E67E22]/5', 
        textColor: 'text-[#E67E22] dark:text-[#E67E22]',
    }
];

const WordCarousel = () => {
    const [index, setIndex] = useState(0);
    
    useEffect(() => {
        const timer = setInterval(() => {
            setIndex(prev => (prev + 1) % words.length);
        }, 2000);
        
        return () => clearInterval(timer);
    }, []);

    const currentWord = words[index];
    const Icon = currentWord.icon;

    return (
        <div className="relative h-12 sm:h-16 min-w-[10rem] sm:min-w-[12rem]"> 
            <div
                key={index}
                className={`
                    absolute inset-0
                    flex items-center gap-2 px-3 sm:px-4 py-2 rounded-full
                    ${currentWord.bgColor}
                    animate-carousel-slide
                    justify-center
                    w-fit
                    min-w-[10rem] sm:min-w-[12rem]
                `}
            >
                <Icon className={`h-8 w-8 sm:h-10 sm:w-10 shrink-0 ${currentWord.textColor}`} />
                <span className={`text-2xl sm:text-3xl font-semibold whitespace-nowrap ${currentWord.textColor}`}>
                    {currentWord.text}
                </span>
            </div>
        </div>
    );
};

export default WordCarousel;