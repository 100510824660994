import React, { useRef, useEffect, useMemo, useState } from 'react';
import { getModelInfo } from './ModelData';

const ModelsPopup = ({ children, onClose, darkMode, selectedModel, setSelectedModel }) => {
    const popupRef = useRef(null);
    const modelInfo = useMemo(() => getModelInfo(darkMode), [darkMode]);
    const [sortBy, setSortBy] = useState('name');
    const [sortOrder, setSortOrder] = useState('asc');
    const [filter, setFilter] = useState('all');

    // const strengthOptions = ['all', 'Complex tasks', 'Quick, simple tasks', 'Web search'];

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (popupRef.current && !popupRef.current.contains(event.target)) {
                setTimeout(() => {
                    onClose();
                }, 10);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [onClose]);


    // Filter out hidden models
    const filteredModelInfo = useMemo(() => {
        return modelInfo.filter(model => {
            if (filter === 'all') return !model.hidden;
            if (filter === 'other') return !model.hidden && !model.strength;
            return !model.hidden && model.strength === filter;
        });
    }, [modelInfo, filter]);

    const sortedAllOptions = useMemo(() => {
        return [...filteredModelInfo].sort((a, b) => {
            if (sortBy === 'name') {
                return sortOrder === 'asc' ? a.name.localeCompare(b.name) : b.name.localeCompare(a.name);
            } else if (sortBy === 'cost') {
                return sortOrder === 'asc' ? a.cost - b.cost : b.cost - a.cost;
            } else if (sortBy === 'speed') {
                return sortOrder === 'asc' ? a.speed - b.speed : b.speed - a.speed;
            }
            return 0;
        });
    }, [filteredModelInfo, sortBy, sortOrder]);

    const handleSort = (newSortBy) => {
        if (newSortBy === sortBy) {
            setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
        } else {
            setSortBy(newSortBy);
            setSortOrder('asc');
        }
    };

    const handleFilterChange = (newFilter) => {
        setFilter(newFilter);
    };
    
    return (
        <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-10 backdrop-blur-sm p-4">
            <div ref={popupRef} className="w-full md:w-3/4 lg:w-3/4 h-5/6 max-w-3xl flex flex-col bg-white dark:bg-secondary-dark rounded-lg shadow-lg border border-border dark:border-border-dark">
                <div className="flex-shrink-0 px-4 sm:px-6 pt-4 pb-2">
                    <div className="flex flex-col sm:flex-row justify-between items-center space-y-2 sm:space-y-0">
                        <h2 className="text-lg font-semibold mr-4">Select model</h2>
                        <div className="flex flex-wrap gap-2">
                            <div className="hidden sm:flex border border-border dark:border-border-dark p-1 rounded-lg">
                                {['Name', 'Cost', 'Speed'].map((option) => (
                                    <button
                                        key={option}
                                        onClick={() => handleSort(option.toLowerCase())}
                                        className={`flex items-center justify-center text-xs p-1 w-16 rounded-md transition-all duration-300 ease-in-out hover:bg-hover dark:hover:bg-hover-dark ${
                                            sortBy === option.toLowerCase()
                                                ? 'text-primary dark:text-primary'
                                                : 'text-text dark:text-text-dark hover:text-primary-dark'
                                        }`}
                                    >
                                        {option}
                                        <svg className="w-3 h-3 sm:w-4 sm:h-4 ml-1" viewBox="0 0 24 24" fill="none" stroke="#7cb342" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                                            {sortBy === option.toLowerCase() ? (
                                                <path d={sortOrder === 'asc' ? "M18 15l-6-6-6 6" : "M6 9l6 6 6-6"} />
                                            ) : (
                                                <line x1="5" y1="12" x2="19" y2="12" />
                                            )}
                                        </svg>
                                    </button>
                                ))}
                            </div>
                            {/* <div className="flex border border-border dark:border-border-dark p-1 rounded-lg">
                                <select
                                    value={filter}
                                    onChange={(e) => handleFilterChange(e.target.value)}
                                    className="text-xs bg-white dark:bg-secondary-dark text-text dark:text-text-dark p-1"
                                >
                                    {strengthOptions.map((strength) => (
                                        <option key={strength} value={strength}>
                                            {strength === 'all' ? 'Filter by strength' : strength}
                                        </option>
                                    ))}
                                </select>
                            </div> */}
                        </div>  
                    </div>
                </div>
                <div className="flex-grow overflow-y-auto px-6 py-2">
                    {sortedAllOptions.map((model) => (
                        React.cloneElement(children, {
                            key: model.id,
                            model: model,
                            selectedModel: selectedModel,
                            setSelectedModel: setSelectedModel,
                            isPopup: true,
                        })
                    ))}
                </div>
            </div>
        </div>
    );
};

export default ModelsPopup;