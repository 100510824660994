import React from "react";
import { useAuth0 } from "@auth0/auth0-react";

const AuthButton = ({ width = 'w-28' }) => {
  const { isAuthenticated, loginWithRedirect, logout } = useAuth0();

  const getButtonClass = () => {
    if (isAuthenticated) {
      // Logged in
      return `${width} px-4 py-2 text-sm
        bg-white dark:bg-background-dark 
        text-red-600 dark:text-red-400
        border border-red-200 dark:border-red-800
        hover:bg-red-50 dark:hover:bg-red-900/20
        rounded-md transition-colors duration-300`;
    } else {
      // Not logged in
      return `${width} px-2 py-2
        bg-background dark:bg-background-dark 
        text-primary dark:text-primary
        border border-primary dark:border-primary
        hover:bg-primary/10 dark:hover:bg-primary/10
        rounded-md transition-colors duration-300`;
    }
  };

  return (
    <button 
      className={getButtonClass()}
      onClick={() => isAuthenticated 
        ? logout({ returnTo: window.location.origin }) 
        : loginWithRedirect()
      }
    >
      {isAuthenticated ? 'Log Out' : 'Log in'}
    </button>
  );
};

export default AuthButton;