import React from 'react';
import Tooltip from './Tooltip';
import { PlusIcon } from '@heroicons/react/24/outline';

const NewChatButton = ( {
    setMessages, 
    firstMessageSent, 
    setFirstMessageSent, 
    isMinimized, 
    setConversationId, 
    isMobileSidebarOpen
} ) => {

    const handleClick = () => {
        setMessages([]);
        setFirstMessageSent(false);
        setConversationId(null);
    };

    const showFullButton = !isMinimized || isMobileSidebarOpen;

    return (
        <>
            {!showFullButton ? (
                <Tooltip content="Start a new chat" position="right" width="w-32">
                    <button 
                        className={`p-2 rounded-full transition-colors text-sm w-10 ${firstMessageSent ? 'hover:bg-background dark:hover:bg-hover-dark' : 'opacity-50 '}`}
                        onClick={handleClick}
                        aria-label="Start new chat"
                        disabled={!firstMessageSent}
                    >
                        <div className="flex items-center justify-center">
                            <PlusIcon className="h-6 w-6 text-primary" />
                        </div>
                    </button>
                </Tooltip>
            ) : (
                <button 
                    className={`p-2 rounded-full transition-colors text-sm w-44 text-left ${firstMessageSent ? 'hover:bg-background dark:hover:bg-hover-dark' : 'opacity-50 '}`}
                    onClick={handleClick}
                    aria-label="Start new chat"
                    disabled={!firstMessageSent}
                >
                    <div className="flex items-center justify-start">
                        <PlusIcon className="h-6 w-6 text-primary" />
                        <span className="ml-2 text-text dark:text-text-dark">Start new chat</span>
                    </div>
                </button>
            )}
        </>
    );
};

export default NewChatButton;