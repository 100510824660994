import React from 'react';
import { Link } from 'react-router-dom';

export default function Footer() {

    return (
        <footer className="bg-white dark:bg-secondary-dark py-6">
            <div className="container mx-auto px-6 flex flex-col sm:flex-row items-center justify-between gap-4">
                <div className="flex items-center gap-2">
                    <span className="text-sm text-gray-500">
                        Copyright © {new Date().getFullYear()} Elly ApS (CVR 45253384). All rights reserved.
                    </span>
                </div>

                <nav className="flex gap-6">
                    <Link to="/terms" className="text-sm text-gray-500 hover:text-primary">
                        Terms
                    </Link>
                    <Link to="/privacy" className="text-sm text-gray-500 hover:text-primary">
                        Privacy
                    </Link>
                    <a 
                        href="mailto:hi@ask-elly.co"
                        className="text-sm text-gray-500 hover:text-primary"
                    >
                        Contact
                    </a>
                </nav>
            </div>
        </footer>
    );
}