import NewChatButton from './NewChatButton';
import ChatHistory from './ChatHistory';
import Logo from './Logo';
import { Bars3Icon } from '@heroicons/react/24/outline';

const SideBar = ({ 
  isMinimized, 
  setIsMinimized,
  isMobileSidebarOpen,
  setIsMobileSidebarOpen,
  setSelectedModel, 
  setMessages, 
  firstMessageSent, 
  setFirstMessageSent, 
  user, 
  setConversations, 
  conversationId,
  setConversationId, 
  onShowSearch,
  onEditTitle,
  starredConversations,
  setStarredConversations,
  nonStarredConversations,
  setNonStarredConversations,
  onStarConversation,
  conversationPopup,
  setConversationPopup,
  onClearConversation
}) => {
  
  const showFullContent = !isMinimized || isMobileSidebarOpen;

  return (
    <>
      {/* Overlay for mobile - darkens the background when sidebar is open */}
      {isMobileSidebarOpen && (
        <div 
          className="lg:hidden fixed inset-0 bg-black bg-opacity-50 z-40"
          onClick={() => setIsMobileSidebarOpen(false)}
        />
      )}

       {/* Sidebar */}
       <div className={`
        fixed top-0 left-0 h-screen bg-secondary dark:bg-secondary-dark shadow-lg 
        transition-all duration-200 ease-in-out lg:translate-x-0 
        ${isMobileSidebarOpen ? 'translate-x-0' : '-translate-x-full lg:translate-x-0'}
        ${isMinimized ? 'lg:w-16' : 'lg:w-64'}
        w-64 z-50
      `}>
        <div className="flex flex-col h-full">
          <div className="flex flex-col items-start p-3">
            {/* Logo and title */}
            <div className={`flex items-center whitespace-nowrap overflow-hidden px-1 pt-2 ${showFullContent ? 'w-full' : 'w-10'}`}>
              <Logo size="medium" />
              {showFullContent && (
                <span className="text-lg ml-2">
                  Elly
                </span>
              )}
            </div>

            <div className="h-3" />

            {/* Sidebar toggle button */}
            <button
              onClick={() => {
                if (window.innerWidth < 1024) {
                  setIsMobileSidebarOpen(false);
                } else {
                  setIsMinimized(!isMinimized);
                }
              }}
              className={`p-2 rounded-full hover:bg-background dark:hover:bg-hover-dark ${showFullContent ? 'w-44 text-left' : 'w-10'}`}
              aria-label={showFullContent ? "Hide sidebar" : "Show sidebar"}
            >
              <div className="flex items-center">
                <Bars3Icon className="h-6 w-6 text-primary" />
                {showFullContent && (
                  <span className="ml-2 text-sm text-text dark:text-text-dark">
                    Hide sidebar
                  </span>
                )}
              </div>
            </button>

            <div className="h-2" />

            {/* New Chat Button */}
            <NewChatButton
              setMessages={setMessages}
              firstMessageSent={firstMessageSent}
              setFirstMessageSent={setFirstMessageSent}
              isMinimized={isMinimized}
              setConversationId={setConversationId}
              isMobileSidebarOpen={isMobileSidebarOpen}
            />
          </div>

          {/* Chat History */}
          {showFullContent && (
            <div className="flex-1 overflow-hidden">
              <ChatHistory
                user={user}
                setMessages={setMessages}
                setFirstMessageSent={setFirstMessageSent}
                setConversations={setConversations}
                conversationId={conversationId}
                setConversationId={setConversationId}
                setSelectedModel={setSelectedModel}
                onShowSearch={onShowSearch}
                onEditTitle={onEditTitle}
                starredConversations={starredConversations}
                setStarredConversations={setStarredConversations}
                nonStarredConversations={nonStarredConversations}
                setNonStarredConversations={setNonStarredConversations}
                onStarConversation={onStarConversation}
                conversationPopup={conversationPopup}
                setConversationPopup={setConversationPopup}
                onClearConversation={onClearConversation}
              />
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default SideBar;