import React from 'react';

export default function CookiePolicy() {
    return (
        <div className="pt-24 pb-12 px-6 max-w-4xl mx-auto bg-white dark:bg-secondary-dark">
            <h1 className="text-3xl font-bold text-gray-900 dark:text-gray-100 mb-4">
                COOKIE POLICY
            </h1>
            <p className="text-sm text-gray-600 dark:text-gray-400 mb-8">
                Last updated November 27, 2024
            </p>
            
            <div className="prose dark:prose-invert max-w-none">
                <section className="mb-8">
                    <p className="text-gray-600 dark:text-gray-400">
                        This Cookie Policy explains how Elly ApS ("we", "us", and "our") uses cookies and similar technologies 
                        on our website 
                        <a href="https://ask-elly.co" className="text-text dark:text-text-dark ml-1" target="_blank" rel="noopener noreferrer">
                            ask-elly.co
                        </a> ("Website").
                    </p>    
                </section>

                <section className="mb-8">
                    <h2 className="text-xl font-semibold text-gray-900 dark:text-gray-100 mb-4">What Are Cookies?</h2>
                    <p className="text-gray-600 dark:text-gray-400">
                        Cookies are small data files that are placed on your computer or mobile device when you visit a website. 
                        Cookies are widely used by website owners in order to make their websites work, or to work more efficiently, 
                        as well as to provide reporting information. 
                        
                        Cookies set by the website owner (in this case, Elly ApS) are called "first party cookies". 
                        Cookies set by parties other than the website owner are called "third party cookies". These cookies enable 
                        third-party features or functionality to be provided on through the website (e.g., advertising, interactive content, 
                        and analytics). The parties setting these cookies can recognize your computer both when it visits the website in 
                        question and also when it visits certain other websites.
                    </p>
                </section>

                <section className="mb-8">
                    <h2 className="text-xl font-semibold text-gray-900 dark:text-gray-100 mb-4">Why and how do we use cookies?</h2>
                    <p className="text-gray-600 dark:text-gray-400">
                        <em><strong>In short:</strong> We only use cookies that are strictly necessary for our site to work.</em> 
                    </p>

                    <p className="text-gray-600 dark:text-gray-400 mb-4">
                        The only cookies we use are so-called "essential cookies". These let us remember your preferences 
                        so you don't have to re-enter them every time you visit our site.
                    </p>

                    <p className="text-gray-600 dark:text-gray-400 mb-4">
                        We do not allow any non-essential third-party cookies on our site. This means that we do not let other parties track 
                        your activity on our site (for example, with the intent to show you targeted ads).
                    </p>

                    <p className="text-gray-600 dark:text-gray-400 mb-4">
                        We currently use the following cookies. Below you can see what they do. We may add more in the future, and will update 
                        this policy accordingly.
                    </p>

                    <div className="ml-4 text-gray-600 dark:text-gray-400">
                        <p className="font-medium mb-2">"darkMode": </p>
                        <ul className="list-disc ml-6 space-y-1">
                            <li>Purpose: Saves your display preference (either dark or light mode)</li>
                            <li>Duration: Persistent</li>
                            <li>Provider: Elly</li>
                        </ul>

                        <p className="font-medium mb-2 mt-4">"cf_clearance": </p>
                        <ul className="list-disc ml-6 space-y-1">
                            <li>Purpose: Security cookie from Cloudflare to verify legitimate website visitors</li>
                            <li>Duration: 30 minutes</li>
                            <li>Provider: Cloudflare</li>
                        </ul>

                        <p className="font-medium mb-2 mt-4">"Priority": </p>
                        <ul className="list-disc ml-6 space-y-1">
                            <li>Purpose: Security cookie used by Cloudflare to manage request prioritization and protect against malicious traffic</li>
                            <li>Duration: Session (expires when browser closes)</li>
                            <li>Provider: Cloudflare</li>
                        </ul>
                    </div>
                </section>

                <section className="mb-8">
                    <h2 className="text-xl font-semibold text-gray-900 dark:text-gray-100 mb-4">How can you control cookies?</h2>
                    <p className="text-gray-600 dark:text-gray-400">
                        <em><strong>In short:</strong> Because we only use "essential cookies", there are no settings for you to manage.</em> 
                    </p>
                    <p className="text-gray-600 dark:text-gray-400">
                        On our site, you do not see a cookie banner or a cookie consent manager. This is because we do not use any cookies of 
                        sort you're typically asked to consent to (non-essential third-party cookies). The only cookies we use are the ones that make our site 
                        work, and these cookies are mandatory. Therefore, there are no cookie settings for you to manage. 
                    </p>
                    <p className="text-gray-600 dark:text-gray-400">
                        In addition, your browser may give you options to control cookies:
                        <ul className="list-disc ml-6 space-y-2 text-gray-600 dark:text-gray-400">
                            <li>You can clear cookies at any time through your browser settings</li>
                            <li>You can use your browser in private/incognito mode</li>
                            <li>You can configure your browser to block cookies (though this may affect site functionality)</li>
                        </ul>
                    </p>
                </section>

                <section className="mb-8">
                    <h2 className="text-xl font-semibold text-gray-900 dark:text-gray-100 mb-4">How about other tracking technologies?</h2>
                    <p className="text-gray-600 dark:text-gray-400">
                        <em><strong>In short:</strong> We don't use any other tracking technologies.</em> 
                    </p>
                    <p className="text-gray-600 dark:text-gray-400">
                        In line with our stance on cookies, we do not use other tracking technologies such as web beacons, tracking pixels, 
                        browser fingerprinting either.
                    </p>
                </section>

                <section className="mb-8">
                    <h2 className="text-xl font-semibold text-gray-900 dark:text-gray-100 mb-4">How do you handle authentication?</h2>
                    <p className="text-gray-600 dark:text-gray-400">
                        We use Auth0 for secure authentication. You can learn more about Auth0's privacy practices in their{' '}
                        <a href="https://auth0.com/privacy" className="text-text dark:text-text-dark hover:underline" target="_blank" rel="noopener noreferrer">
                            privacy policy
                        </a>.
                    </p>
                </section>

                <section className="mb-8">
                    <h2 className="text-xl font-semibold text-gray-900 dark:text-gray-100 mb-4">Do you serve targeted ads?</h2>
                    <p className="text-gray-600 dark:text-gray-400">
                        No. We do not show you adds nor do we help others target you with adds.
                    </p>
                </section>

                <section className="mb-8">
                    <h2 className="text-xl font-semibold text-gray-900 dark:text-gray-100 mb-4">How often will you update this Cookie Policy?</h2>
                    <p className="text-gray-600 dark:text-gray-400">
                        We regularly review and update our cookie practices and this policy. We'll notify you of any 
                        material changes through our website.

                        We may update this Cookie Policy from time to time in order to reflect, for example, changes to the cookies we use or for other 
                        operational, legal or regulatory reasons. Please therefore revisit this Cookie Policy regularly to stay informed about our 
                        use of cookies.
                    </p>
                    <p className="text-gray-600 dark:text-gray-400">
                        The date at the top of this Cookie Policy indicates when it was last updated. 
                    </p>
                </section>

                <section className="mb-8">
                    <h2 className="text-xl font-semibold text-gray-900 dark:text-gray-100 mb-4">Where can I get more information?</h2>
                    <p className="text-gray-600 dark:text-gray-400">
                        If you have questions about our cookie usage, please contact us at{' '}
                        <a href="mailto:privacy@ask-elly.co" className="text-text dark:text-text-dark hover:underline" target="_blank" rel="noopener noreferrer">
                            privacy@ask-elly.co
                        </a>.
                    </p>
                </section>
            </div>
        </div>
    );
}