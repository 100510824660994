import React from 'react';
import { Link } from 'react-router-dom';

const PrivacyPolicy = () => {
  return (
    <div className="max-w-4xl mx-auto mt-12 px-6 py-12 bg-white dark:bg-secondary-dark">
        <h1 className="text-3xl font-bold text-gray-900 dark:text-gray-100 mb-4">
            PRIVACY POLICY
        </h1>
        <p className="text-sm text-gray-600 dark:text-gray-400 mb-8">
            Last updated November 22, 2024
        </p>

        <div id="intro" className="prose dark:prose-invert max-w-none">
            <section className="mb-8">  
                <h2 className="text-xl font-semibold text-gray-900 dark:text-gray-100 mb-4">
                    INTRODUCTION
                </h2>
                <p className="text-gray-600 dark:text-gray-400">
                    This Privacy Policy for Elly ApS (doing business as Elly) ('we', 'us', or 'our'), 
                    describes how and why we might access, collect, store, use, and/or share ('process') 
                    your personal information when you use our services ('Services'), including when you:
                </p>
                <ul className="list-disc pl-6 mt-4 mb-6 text-gray-600 dark:text-gray-400">
                    <li className="mb-2">
                        Visit our website at ask-elly.co, or any website of ours that links to this Privacy Policy
                    </li>
                    <li className="mb-2">
                        Use Elly, our Artificial Intelligence application that lets users interact with different language models
                    </li>
                    <li className="mb-2">
                        Engage with us in other related ways, including any sales, marketing, or events
                    </li>
                </ul>
                <p className="text-gray-600 dark:text-gray-400">
                    <strong>Questions or concerns?</strong> Reading this Privacy Policy will help you understand your privacy 
                    rights and choices. We are responsible for making decisions about how your personal information is processed. 
                    If you do not agree with our policies and practices, please do not use our Services. If you still have any 
                    questions or concerns, please contact us at 
                    <a href="mailto:privacy@ask-elly.co" className="text-text dark:text-text-dark ml-1">
                        privacy@ask-elly.co
                    </a>
                    .
                </p>
            </section>
            <hr className="my-8" />
            <section className="mb-8" id="summary">
                <h2 className="text-xl font-semibold text-gray-900 dark:text-gray-100 mb-4">
                    POLICY SUMMARY
                </h2>
                <p className="text-gray-600 dark:text-gray-400 italic mb-4">
                    This summary provides key points from our Privacy Policy, but you can find more details about any of these 
                    topics by clicking the link following each key point or by using our table of contents below to find the 
                    section you are looking for.
                </p>
                <div className="space-y-4">
                    <p className="text-gray-600 dark:text-gray-400">
                        <strong>What personal information do we process?</strong> When you visit, use, or navigate 
                        our Services, we may process personal information depending on how you interact with us 
                        and the Services, the choices you make, and the products and features you use. Learn more about 
                        <a href="#infocollect" className="text-text dark:text-text-dark ml-1">
                            personal information you disclose to us
                        </a>.
                    </p>
                    <p className="text-gray-600 dark:text-gray-400">
                        <strong>Do we process any sensitive personal information?</strong> We do not process 
                        sensitive personal information.
                    </p>
                    <p className="text-gray-600 dark:text-gray-400">
                        <strong>Do we collect any information from third parties?</strong> We do not collect any 
                        information from third parties.
                    </p>
                    <p className="text-gray-600 dark:text-gray-400">
                        <strong>How do we process your information?</strong> We process your information to provide, 
                        improve, and administer our Services, communicate with you, for security and fraud prevention, 
                        and to comply with law. We may also process your information for other purposes with your consent.
                        We process your information only when we have a valid legal reason to do so. Learn more about 
                        <a href="#infouse" className="text-text dark:text-text-dark ml-1">
                            how we process your information
                        </a>.
                    </p>
                    <p className="text-gray-600 dark:text-gray-400">
                        <strong>In what situations and with which parties do we share personal information?</strong> 
                        We may share information in specific situations and with specific third parties. Learn more about 
                        <a href="#whoshare" className="text-text dark:text-text-dark ml-1">
                            when and with whom we share your personal information
                        </a>.
                    </p>
                    <p className="text-gray-600 dark:text-gray-400">
                        <strong>How do we keep your information safe?</strong> We have adequate organisational and technical 
                        processes and procedures in place to protect your personal information. However, no data transmission 
                        over the internet or information storage technology can be guaranteed to be 100% secure, so we cannot 
                        promise or guarantee that hackers, cybercriminals, or other unauthorized third parties will not be able 
                        to defeat our security and improperly collect, access, steal, or modify your information. Learn more about
                        <a href="#infosafe" className="text-text dark:text-text-dark ml-1">
                            how we keep your information safe
                        </a>.
                    </p>
                    <p className="text-gray-600 dark:text-gray-400">
                        <strong>What are your rights?</strong> Depending on where you are located geographically, the applicable 
                        privacy law may mean you have certain rights regarding your personal information. Learn more about 
                        <a href="#privacyrights" className="text-text dark:text-text-dark ml-1">
                            your privacy rights
                        </a>.
                    </p>
                    <p className="text-gray-600 dark:text-gray-400">
                        <strong>How do you exercise your rights?</strong> The easiest way to exercise your rights is by contacting 
                        us on <a href="mailto:privacy@ask-elly.co" className="text-text dark:text-text-dark ml-1">
                            privacy@ask-elly.co
                        </a>. We will consider and act upon any request in accordance with applicable data protection laws.
                    </p>
                </div>
            </section>
            <hr className="my-8" />

            <section className="mb-8" id="toc">
                <h2 className="text-xl font-semibold text-gray-900 dark:text-gray-100 mb-4">
                    FULL POLICY
                </h2>
                <h2 className="text-xl font-semibold text-gray-900 dark:text-gray-100 mb-4">
                    Table of Contents
                </h2>
                <ul className="list-decimal pl-6 space-y-2 text-gray-600 dark:text-gray-400">
                    <li><a href="#infocollect" className="text-text dark:text-text-dark">What information do we collect?</a></li>
                    <li><a href="#infouse" className="text-text dark:text-text-dark">How do we process your information?</a></li>
                    <li><a href="#legalbases" className="text-text dark:text-text-dark">What legal bases do we rely on to process your information?</a></li>
                    <li><a href="#whoshare" className="text-text dark:text-text-dark">When and with whom do we share your personal information?</a></li>
                    <li><a href="#cookies" className="text-text dark:text-text-dark">Do we use cookies and other tracking technologies?</a></li>
                    <li><a href="#ai" className="text-text dark:text-text-dark">Do we offer artificial intelligence based products?</a></li>
                    <li><a href="#sociallogins" className="text-text dark:text-text-dark">How do we handle your social logins?</a></li>
                    <li><a href="#inforetain" className="text-text dark:text-text-dark">How long do we keep your information?</a></li>
                    <li><a href="#infosafe" className="text-text dark:text-text-dark">How do we keep your information safe?</a></li>
                    <li><a href="#minors" className="text-text dark:text-text-dark">Do we collect information from minors?</a></li>
                    <li><a href="#privacyrights" className="text-text dark:text-text-dark">What are your privacy rights?</a></li>
                    <li><a href="#DNT" className="text-text dark:text-text-dark">Controls for Do-Not-Track features</a></li>
                    <li><a href="#usprivacy" className="text-text dark:text-text-dark">Do United States residents have specific privacy rights?</a></li>
                    <li><a href="#policyupdates" className="text-text dark:text-text-dark">Do we make updates to this policy?</a></li>
                    <li><a href="#contact" className="text-text dark:text-text-dark">How can you contact us about this policy?</a></li>
                    <li><a href="#request" className="text-text dark:text-text-dark">How can you review, update, or delete the data we collect from you?</a></li>
                </ul>
            </section>

            <section className="mb-8" id="infocollect">
                <h2 className="text-xl font-semibold text-gray-900 dark:text-gray-100 mb-4">
                    1. What information do we collect?
                </h2>
                <div className="space-y-4">
                    <p className="text-gray-600 dark:text-gray-400">
                        <strong>Personal information you disclose to us</strong>
                    </p>
                    <p className="text-gray-600 dark:text-gray-400">
                        <em><strong>In Short:</strong> We collect personal information that you provide to us.</em>
                    </p>
                    <p className="text-gray-600 dark:text-gray-400">
                        We collect personal information that you voluntarily provide to us when you register on the Services, 
                        express an interest in obtaining information about us or our products and Services, when you 
                        participate in activities on the Services, or otherwise when you contact us.
                    </p>
                    <p className="text-gray-600 dark:text-gray-400">
                        <strong>Personal Information Provided by You.</strong> The personal information that we collect 
                        depends on the context of your interactions with us and the Services, the choices you make, and 
                        the products and features you use. The personal information we collect may include the following:
                    </p>
                    <ul className="list-disc pl-6 space-y-2 text-gray-600 dark:text-gray-400">
                        <li>names</li>
                        <li>email addresses</li>
                        <li>profile pictures</li>
                        <li>contact or authentication data</li>
                        <li>usernames</li>
                        <li>passwords</li>
                        <li>contact preferences</li>
                        <li>mailing addresses</li>
                        <li>occupations</li>
                    </ul>
                    <p className="text-gray-600 dark:text-gray-400">
                        <strong>Sensitive Information.</strong> We do not process sensitive information.
                    </p>
                    <p className="text-gray-600 dark:text-gray-400">
                        <strong>Payment Data.</strong> We may collect data necessary to process your payment if you choose to 
                        make purchases, such as your payment instrument number, and the security code associated with your payment 
                        instrument. All payment data is handled and stored by Stripe. You may find their privacy policy here: 
                        <a href="https://stripe.com/privacy" className="text-text dark:text-text-dark ml-1" target="_blank" rel="noopener noreferrer">
                            https://stripe.com/privacy
                        </a>.
                    </p>
                    <p className="text-gray-600 dark:text-gray-400">
                        <strong>Social Media Login Data.</strong> We may provide you with the option to register using your 
                        existing social media account details, like your Facebook, X, Github, or other social media account. If you choose 
                        to register in this way, we will collect the information described in the section called 
                        <a href="#sociallogins" className="text-text dark:text-text-dark ml-1">
                            "How do we handle your social logins?"
                        </a> below.
                    </p>
                    <p className="text-gray-600 dark:text-gray-400">
                        All personal information that you provide to us must be true, complete, and accurate, and you must 
                        notify us of any changes to such personal information.
                    </p>
                </div>
                <div className="space-y-4">
                    <p className="text-gray-600 dark:text-gray-400">
                        <strong>Information automatically collected</strong>
                    </p>
                    <p className="text-gray-600 dark:text-gray-400">
                        <em><strong>In Short:</strong> Some information - such as your Internet Protocol (IP) address and/or 
                        browser and device characteristics - is collected automatically when you visit our Services.</em>
                    </p>
                    <p className="text-gray-600 dark:text-gray-400">
                        We automatically collect certain information when you visit, use, or navigate the Services. 
                        This information does not reveal your specific identity (like your name or contact information) 
                        but may include device and usage information, such as your IP address, browser and device characteristics, 
                        operating system, language preferences, referring URLs, device name, country, location, information about 
                        how and when you use our Services, and other technical information. This information is primarily needed 
                        to maintain the security and operation of our Services, and for our internal analytics and reporting 
                        purposes.
                    </p>
                    <p className="text-gray-600 dark:text-gray-400">
                        Like many businesses, we also collect information through cookies and similar technologies. You can find our 
                        more about this in our 
                        <Link to="/cookies" className="text-text dark:text-text-dark ml-1">
                            Cookie Policy
                        </Link>.
                    </p>
                    <p className="text-gray-600 dark:text-gray-400">
                        The information we collect includes:
                    </p>
                    <ul className="list-disc pl-6 space-y-2 text-gray-600 dark:text-gray-400">
                        <li>
                            <em>Log and Usage Data.</em> Log and usage data is service-related, diagnostic, usage, and performance 
                            information our servers automatically collect when you access or user our Services and which we record in 
                            log files. Depending on how you interact with us, this log data may include your IP address, device information,
                            browser type, and settings and information about your activity in the Services (such as the date/time stamps 
                            associated with your usage, pages and files viewed, searches, and other actions you take such as which features 
                            you use), device event information (such as system activity, error reports (sometimes called 'crash dumps'), and 
                            hardware settings).
                        </li>
                        <li>
                            <em>Device Data.</em> We collect device data such as information about your computer, phone, tablet, or other 
                            device you use to access the Services. Depending on the device used, this device data may include information 
                            such as your IP address (or proxy server), device and application identification numbers, location, browser type, 
                            hardware model, Internet service provider and/or mobile carrier, operating system, and system configuration 
                            information.
                        </li>
                        <li>
                            <em>Location Data.</em> We collect location data such as information about your device's location, which can be 
                            either precise or imprecise. How much information we collect depends on the type and setting of the device you use 
                            to access the Services. For example, we may use the GPS and other technologies to collect geolocation data that tells 
                            us your current location (based on your IP address). You can opt out of allowing us to collect this information either 
                            by refusing access to the information or by disabling the Location setting on your device. However, if you choose to opt 
                            out, you may not be able to use certain aspects of the Services.
                        </li>
                    </ul>
                </div>
            </section>

            <section className="mb-8" id="infouse">
                <h2 className="text-xl font-semibold text-gray-900 dark:text-gray-100 mb-4">
                    2. How do we process your information?
                </h2>
                <div className="space-y-4">
                    <p className="text-gray-600 dark:text-gray-400">
                        <em><strong>In Short:</strong> We process your information to provide, improve, and administer 
                        our Services, communicate with you, for security and fraud prevention, and to comply with law. 
                        We may also process your information for other purposes with your consent.</em>
                    </p>
                    <p className="text-gray-600 dark:text-gray-400">
                        We process your personal information for a variety of reasons, depending on how you interact 
                        with our Services, including:
                    </p>
                    <ul className="list-disc pl-6 space-y-2 text-gray-600 dark:text-gray-400">
                        <li>
                            <strong>To facilitate account creation and authentication and otherwise manage user accounts.</strong> 
                            We may process your information so you can create and log in to your account, as well as keep 
                            your account in working order.
                        </li>
                        <li>
                            <strong>To deliver and facilitate delivery of services to the user.</strong> We may process 
                            your information to provide you with the requested service.
                        </li>
                        <li>
                            <strong>To respond to user inquiries/offer support to users.</strong> We may process your 
                            information to respond to your inquiries and solve any potential issues you might have with 
                            the requested service.
                        </li>
                        <li>
                            <strong>To send administrative information to you.</strong> We may process your information 
                            to send you details about our products and services, changes to our terms and policies, and 
                            other similar information.
                        </li>
                        <li>
                            <strong>To fulfil and manage your orders.</strong> We may process your information to 
                            fulfil and manage your orders, payments, returns, and exchanges made through the Services.
                        </li>
                        <li>
                            <strong>To request feedback.</strong> We may process your information when necessary to request 
                            feedback and to contact you about your use of our Services.
                        </li>
                        <li>
                            <strong>To send you marketing and promotional communications.</strong> We may process the personal 
                            information you send to us for our marketing purposes, if this is in accordance with your marketing 
                            preferences. You can opt out of our marketing emails at any time. For more information, see 
                            <a href="#privacyrights" className="text-text dark:text-text-dark ml-1">
                                "What are your privacy rights?"
                            </a>.
                        </li>
                        <li>
                            <strong>To protect our Services.</strong> We may process your information as part of our efforts 
                            to keep our Services safe and secure, including fraud monitoring and prevention.
                        </li>
                        <li>
                            <strong>To identify usage trends.</strong> We may process information about how you use our Services 
                            to better understand how they are being used so we can improve them.
                        </li>
                        <li>
                            <strong>To identify usage trends.</strong> We may process information about how you use our Services 
                            to better understand how they are being used so we can improve them.
                        </li>
                        <li>
                            <strong>To determine the effectiveness of our marketing and promotional campaigns.</strong> We may process 
                            your information to better understand how to provide marketing and promotional campaigns that are most 
                            relevant to you.
                        </li>
                        <li>
                            <strong>To save or protect an individual's vital interest.</strong> We may process your information when 
                            necessary to save or protect and individual's vital interest, such as to prevent harm.
                        </li>
                    </ul>
                </div>
            </section>

            <section className="mb-8" id="legalbases">
                <h2 className="text-xl font-semibold text-gray-900 dark:text-gray-100 mb-4">
                    3. What legal bases do we rely on to process your information?
                </h2>
                <div className="space-y-4">
                    <p className="text-gray-600 dark:text-gray-400">
                        <em><strong>In Short:</strong> We only process your personal information when we believe it is 
                        necessary and we have a valid legal reason (i.e., legal basis) to do so under applicable law, 
                        like with your consent, to comply with laws, to provide you with services to enter into or 
                        fulfill our contractual obligations, to protect your rights, or to fulfill our legitimate 
                        business interests.</em>
                    </p>
                    <p className="text-gray-600 dark:text-gray-400">
                        <em><strong>If you are located in the EU or UK, this section applies to you.</strong></em>
                    </p>
                    <p className="text-gray-600 dark:text-gray-400">
                        The General Data Protection Regulation (GDPR) and UK GDPR require us to explain the valid 
                        legal bases we rely on in order to process your personal information. As such, we may rely 
                        on the following legal bases to process your personal information:
                    </p>
                    <ul className="pl-6 space-y-2 text-gray-600 dark:text-gray-400">
                        <li>
                            <strong>Consent.</strong> We may process your information if you have given us permission 
                            (i.e., consent) to use your personal information for a specific purpose. You can withdraw 
                            your consent at any time. Learn more about 
                            <a href="#withdrawconsent" className="text-text dark:text-text-dark ml-1">
                                withdrawing your consent
                            </a>.
                        </li>
                        <li>
                            <strong>Performance of a Contract.</strong> We may process your personal information when 
                            we believe it is necessary to fulfill our contractual obligations to you, including 
                            providing our Services or at your request prior to entering into a contract with you.
                        </li>
                        <li>
                            <strong>Legitimate Interests.</strong> We may process your information when we believe it 
                            is reasonably necessary to achieve our legitimate business interests and those interests do 
                            not outweigh your interests and fundamental rights and freedoms. For example, we may process 
                            your personal information for some of the purposes described in order to:
                            <ul className="pl-6 space-y-2 text-gray-600 dark:text-gray-400">
                                <li>Send users information about special offers and discounts on our products and services</li>
                                <li>Analyse how our Services are used so we can improve them to engage and retain users</li>
                                <li>Support our marketing activities</li>
                                <li>Diagnose problems and/or prevent fraudulent activities</li>
                                <li>Understand how our users use our products and services so we can improve user experience</li>
                            </ul>
                        </li>
                        <li>
                            <strong>Legal Obligations.</strong> We may process your information where we believe it 
                            is necessary for compliance with our legal obligations, such as to cooperate with a law 
                            enforcement body or regulatory agency, exercise or defend our legal rights, or disclose 
                            your information as evidence in litigation in which we are involved.
                        </li>
                        <li>
                            <strong>Vital Interests.</strong> We may process your information where we believe it is necessary 
                            to protect your vital interests or the vital interests of a third party, such as situations involving 
                            potential threats to the safety of any person.
                        </li>
                    </ul>
                    <p className="text-gray-600 dark:text-gray-400">
                        <em><strong>If you are located in Canada, this section applies to you.</strong></em>
                    </p>
                    <p className="text-gray-600 dark:text-gray-400">
                        We may process your information if you have given us specific permission (i.e., express consent) to use your 
                        personal information for a specific purpose, or in situations where your permission can be inferred (i.e., 
                        implied consent). You can 
                        <a href="#withdrawconsent" className="text-text dark:text-text-dark ml-1">
                            withdraw your consent
                        </a> at any time.
                    </p>
                    <p className="text-gray-600 dark:text-gray-400">
                        In some exceptional cases, we may be legally permitted under applicable law to process your information, 
                        without your consent, including, for example:
                        <ul className="pl-6 space-y-2 text-gray-600 dark:text-gray-400">
                            <li>If collection is clearly in the interest of an individual and consent cannot be obtained in a timely way</li>
                            <li>For investigations and fraud detection and prevention</li>
                            <li>For business transactions provided certain conditions are met</li>
                            <li>If it is contained in a witness statement and the collection is necessary to assess, process, or settle an 
                                insurance claim
                            </li>
                            <li>For identifying injured, ill, or deceased persons and communicating with next of kin</li>
                            <li>If we have reasonable grounds to believe an individual has been, is, or may be victim of financial abuse</li>
                            <li>If it is reasonable to expect collection and use with consent would compromise the availability of the accuracy 
                                of the information and the collection is reasonable for purposes related to investigating a breach of an agreement 
                                or a contravention of the laws of Canada or a province
                            </li>
                            <li>If disclosure is required to comply with a subpoena, warrant, court order, or rules of the court relating to the 
                                production of records
                            </li>
                            <li>If it was producted by an individual in the course of their employment, business, or profession and the collection 
                                is consistent with the purposes for which the information was produced
                            </li>
                            <li>If the collection is solely for journalistic, artistic, or literary purposes</li>
                            <li>If the information is publicly available and is specified by the regulations</li>
                        </ul>
                    </p>
                </div>
            </section>

            <section className="mb-8" id="whoshare">
                <h2 className="text-xl font-semibold text-gray-900 dark:text-gray-100 mb-4">
                    4. When and with whom do we share your personal information?
                </h2>
                <div className="space-y-4">
                    <p className="text-gray-600 dark:text-gray-400">
                        <em><strong>In Short:</strong> We may share information in specific situations described in 
                        this section and/or with the following categories of third parties.</em>
                    </p>

                    <p className="text-gray-600 dark:text-gray-400">
                        <strong>Vendors, Consultants, and Other Third-Party Service Providers.</strong> We may share your data 
                        with third-party vendors, service providers, contractors, or agents ('third parties') who perform services 
                        for us or on our behalf and require access to such information to do that work. 

                        The categories of third parties we may share personal information with are as follows:
                        <ul className="pl-6 space-y-2 text-gray-600 dark:text-gray-400">
                            <li>Cloud Computing Service Providers</li>
                            <li>Website Hosting Service Providers</li>
                            <li>User Account Registration & Authentication Services</li>
                            <li>Payment Processors</li>
                            <li>Data Storage Service Providers</li>
                            <li>AI Platforms</li>

                        </ul>
                    </p>
                    <p className="text-gray-600 dark:text-gray-400">
                        We also may need to share your personal information in the following situations:
                    </p>
                    <ul className="list-disc pl-6 space-y-2 text-gray-600 dark:text-gray-400">
                        <li>
                            <strong>Business Transfers.</strong> We may share or transfer your information in connection 
                            with, or during negotiations of, any merger, sale of company assets, financing, or acquisition 
                            of all or a portion of our business to another company.
                        </li>
                    </ul>
                    <ul className="list-disc pl-6 space-y-2 text-gray-600 dark:text-gray-400">
                        <li>
                            <strong>Business Partners.</strong> We may share your information with our business partners to 
                            offer you certain products, services, or promotions.
                        </li>
                    </ul>
                </div>
            </section>

            <section className="mb-8" id="cookies">
                <h2 className="text-xl font-semibold text-gray-900 dark:text-gray-100 mb-4">
                    5. Do we use cookies and other tracking technologies?
                </h2>
                <div className="space-y-4">
                    <p className="text-gray-600 dark:text-gray-400">
                        <em><strong>In Short:</strong> We only use cookies and similar tracking technologies to maintain 
                        the functionality and security of our Services.</em>
                    </p>
                    <p className="text-gray-600 dark:text-gray-400">
                        We may use cookies and similar tracking technologies (like web beacons and pixels) to gather 
                        information when you interact with our Services. We keep our use of such technologies to a minimum 
                        and only use them to maintain the functionality and security of our Services.
                    </p>
                    <p className="text-gray-600 dark:text-gray-400">
                        We <strong>do not</strong> permit third parties and service providers to use online tracking technologies on our Services 
                        for analytics and advertising.
                    </p>
                    <p className="text-gray-600 dark:text-gray-400">
                        To the extent these online tracking technologies are deemed to be a 'sale'/'sharing' (which includes targeted advertising, 
                        as defined under the applicable laws) under applicable US state laws, you can opt out of these online tracking technologies 
                        by submitting a request as described below under section 
                        <a href="#usprivacyrights" className="text-text dark:text-text-dark ml-1">
                            'Do United States residents have specific privacy rights?'
                        </a>.
                    </p>
                    <p className="text-gray-600 dark:text-gray-400">
                        Specific information about how we use such technologies is set out in our 
                        <Link to="/cookies" className="text-text dark:text-text-dark ml-1">
                            Cookie Policy
                        </Link>.
                    </p>
                </div>
            </section>

            <section className="mb-8" id="cookies">
                <h2 className="text-xl font-semibold text-gray-900 dark:text-gray-100 mb-4">
                    6. Do we offer artificial intelligence-based products?
                </h2>
                <div className="space-y-4">
                    <p className="text-gray-600 dark:text-gray-400">
                        <em><strong>In Short:</strong> We offer products, features, or tools powered by artificial intelligence, 
                        machine learning, or other similar technologies.</em>
                    </p>
                    <p className="text-gray-600 dark:text-gray-400">
                        As part of our Services, we may offer products, features, or tools powered by artificial intelligence, 
                        machine learning, or similar technologies (collectively, 'AI procuts'). These tools are designed to enhance 
                        your experience and provide you with innovative solutions. The terms in this Privacy Policy govern your use 
                        of the AI Products within our Services.
                    </p>
                    <p className="text-gray-600 dark:text-gray-400">
                        <strong>Use of AI Technologies</strong> 
                    </p>
                    <p className="text-gray-600 dark:text-gray-400">
                        We provide the AI Products through third-party service providers ('AI Service Providers'), including Anthropic, 
                        OpenAI, Google Gemini, Mistral AI, and others. As outlined in this Privacy Policy, your input will be shared with 
                        and processed by these AI Service Providers to enable your use of our AI Products for purposes outlined in 
                        <a href="#legalbases" className="text-text dark:text-text-dark ml-1">
                            'What legal bases do we rely on to process your information?'
                        </a>. You must not use the AI Products in any way that violates the terms or policies of any AI Service Provider.
                    </p>
                    <p className="text-gray-600 dark:text-gray-400">
                        <strong>Our AI Products</strong> 
                    </p>
                    <p className="text-gray-600 dark:text-gray-400">
                        Our AI Products are designed for the following functions:
                    </p>
                    <ul className="pl-6 space-y-2 text-gray-600 dark:text-gray-400">
                        <li>AI bots</li>
                        <li>AI search</li>
                        <li>AI research</li>
                        <li>AI automation</li>
                        <li>AI translation</li>
                        <li>AI insights</li>
                        <li>AI document generation</li>
                        <li>Document analysis</li>
                        <li>Image analysis</li>
                        <li>Image generation</li>
                        <li>Video analysis</li>
                        <li>Text analysis</li>
                    </ul>
                    <p className="text-gray-600 dark:text-gray-400">
                        <strong>How We Process Your Data Using AI</strong> 
                    </p>
                    <p className="text-gray-600 dark:text-gray-400">
                        All personal information processed using our AI Products is handled in line with our Privacy Policy and your 
                        personal information is not shared with any AI Service Provider. This ensures high security and safeguards your 
                        personal information throughout the process, giving you peace of mind about your data's safety.
                    </p>
                </div>
            </section>

            <section className="mb-8" id="sociallogins">
                <h2 className="text-xl font-semibold text-gray-900 dark:text-gray-100 mb-4">
                    7. How do we handle your social logins?
                </h2>
                <div className="space-y-4">
                    <p className="text-gray-600 dark:text-gray-400">
                        <em><strong>In Short:</strong> If you choose to register or log in to our Services using a social media account, 
                        we may have access to certain information about you.</em>
                    </p>
                    <p className="text-gray-600 dark:text-gray-400">
                        Our Services offer you the ability to register and log in using your third-party social media account details 
                        (like your Facebook, X, or Github logins). Where you choose to do this, we will receive certain profile information about 
                        you from your social media provider. The profile information we receive may vary depending on the social media 
                        provider concerned, but will not include more than your name, email address, and profile picture.
                    </p>
                    <p className="text-gray-600 dark:text-gray-400">
                        We will use the information we receive only for the purposes that are described in this Privacy Policy or that 
                        are otherwise made clear to you on the relevant Services. Please note that we do not control, and are not 
                        responsible for, other uses of your personal informatino by your third-party social media provider. We recommend 
                        that you review their privacy notice to understand how they collect, use, and share your personal information, 
                        and how you can set your privacy preferences on their sites and apps.
                    </p>
                </div>
            </section>

            <section className="mb-8" id="inforetain">
                <h2 className="text-xl font-semibold text-gray-900 dark:text-gray-100 mb-4">
                    8. How long do we keep your information?
                </h2>
                <div className="space-y-4">
                    <p className="text-gray-600 dark:text-gray-400">
                        <em><strong>In Short:</strong> We keep your information for as long as necessary to fulfill 
                        the purposes outlined in this Privacy Policy unless otherwise required by law.</em>
                    </p>
                    <p className="text-gray-600 dark:text-gray-400">
                        We will only keep your personal information for as long as it is necessary for the purposes 
                        set out in this Privacy Policy, unless a longer retention period is required or permitted by 
                        law (such as tax, accounting, or other legal requirements). No purpose in this policy will 
                        require us keeping your personal information for longer than three (3) months pas the termination 
                        of the user's account.
                    </p>
                    <p className="text-gray-600 dark:text-gray-400">
                        When we have no ongoing legitimate business need to process your personal information, we will 
                        either delete or anonymize such information, or, if this is not possible (for example, because 
                        your personal information has been stored in backup archives), then we will securely store 
                        your personal information and isolate it from any further processing until deletion is possible.
                    </p>
                </div>
            </section>

            <section className="mb-8" id="infosafe">
                <h2 className="text-xl font-semibold text-gray-900 dark:text-gray-100 mb-4">
                    9. How do we keep your information safe?
                </h2>
                <div className="space-y-4">
                    <p className="text-gray-600 dark:text-gray-400">
                        <em><strong>In Short:</strong> We aim to protect your personal information through a system 
                        of organizational and technical security measures.</em>
                    </p>
                    <p className="text-gray-600 dark:text-gray-400">
                        We have implemented appropriate and reasonable technical and organizational security measures 
                        designed to protect the security of any personal information we process. However, despite our 
                        safeguards and efforts to secure your information, no electronic transmission over the Internet 
                        or information storage technology can be guaranteed to be 100% secure, so we cannot promise or 
                        guarantee that hackers, cybercriminals, or other unauthorized third parties will not be able 
                        to defeat our security and improperly collect, access, steal, or modify your information. 
                        Although we will do our best to protect your personal information, transmission of personal 
                        information to and from our Services is at your own risk. You should only access the Services 
                        within a secure environment.
                    </p>
                </div>
            </section>

            <section className="mb-8" id="minors">
                <h2 className="text-xl font-semibold text-gray-900 dark:text-gray-100 mb-4">
                    10. Do we collect information from minors?
                </h2>
                <div className="space-y-4">
                    <p className="text-gray-600 dark:text-gray-400">
                        <em><strong>In Short:</strong> We do not knowingly collect information from or market to children 
                        under 18 years of age.</em>
                    </p>
                    <p className="text-gray-600 dark:text-gray-400">
                        We do not knowingly collect, solicit data from, or market to children under 18 years of age. By using the 
                        Services, you represent that you are at least 18 or that you are the parent or guardian of such a minor and 
                        consent to such minor dependent's use of the Services. If we learn that personal information from users less 
                        than 18 years of age has been collected, we will deactivate the account and take reasonable measures to promptly
                        delete such data from our records. If you become aware of any data we may have collected from children under 
                        age 18, please contact us at 
                        <a href="mailto:privacy@ask-elly.co" className="text-text dark:text-text-dark ml-1">
                            privacy@ask-elly.co
                        </a>.
                    </p>
                </div>
            </section>

            <section className="mb-8" id="privacyrights">
                <h2 className="text-xl font-semibold text-gray-900 dark:text-gray-100 mb-4">
                    11. What are your privacy rights?
                </h2>
                <div className="space-y-4">
                    <p className="text-gray-600 dark:text-gray-400">
                        <em><strong>In Short:</strong> Depending on your state of residence in the US or in some regions, such as the 
                        European Economic Area (EEA), United Kingdom (UK), Switzerland, and Canada you have rights that allow you greater 
                        access to and control over your personal information. You may review, change, or terminate your account at any time,
                        depending on your country, province or state of residence.
                        </em>
                    </p>
                    <p className="text-gray-600 dark:text-gray-400">
                        In some regions (like the EEA, UK, Switzerland, and Canada), you have certain rights under applicable data protection 
                        laws. These may include the right (i) to request access and obtain a copy of your personal information, (ii) to request 
                        rectification or erasure; (iii) to restrict the processing of your personal information; (iv) if applicable, to 
                        data portability; and (v) not to be subject to automated decision-making. In certain circumstances, you may also 
                        have the right to object to the processing of your personal information. You can make such a request by contacting 
                        us using the contact details provided in the section 
                        <a href="#contact" className="text-text dark:text-text-dark ml-1">
                            'How can you contact us about this policy?'
                        </a> below.
                    </p>
                    <p className="text-gray-600 dark:text-gray-400">
                        We will consider and act upon any request in accordance with applicable data protection laws.
                    </p>
                    <p className="text-gray-600 dark:text-gray-400">
                        If you are located in the EEA or UK and you believe we are unlawfully processing your personal information, 
                        you also have the right to complain to your 
                        <a href="https://ec.europa.eu/newsroom/article29/items/612080" className="text-text dark:text-text-dark ml-1" target="_blank" rel="noopener noreferrer">
                            Member State data protection authority
                        </a> or  
                        <a href="https://ico.org.uk/make-a-complaint/data-protection-complaints/" className="text-text dark:text-text-dark ml-1" target="_blank" rel="noopener noreferrer">
                            UK data protection authority
                        </a>.
                    </p>
                    <p className="text-gray-600 dark:text-gray-400">
                        If you are located in Switzerland, you may contact the 
                        <a href="https://www.edoeb.admin.ch/edoeb/en/home.html" className="text-text dark:text-text-dark ml-1" target="_blank" rel="noopener noreferrer">
                            Federal Data Protection and Information Commissioner
                        </a>.
                    </p>
                    <p id="withdrawconsent" className="text-gray-600 dark:text-gray-400 mt-6">
                        <strong>Withdrawing your consent:</strong> If we are relying on your consent to process your personal information, 
                        you have the right to withdraw your consent at any time. You can withdraw your consent at any time by contacting 
                        us using the contact details provided in the section 
                        <a href="#contact" className="text-text dark:text-text-dark ml-1">
                            'How can you contact us about this policy?'
                        </a> below.
                    </p>
                    <p className="text-gray-600 dark:text-gray-400">
                        However, please note that this will not affect the lawfulness of the processing before its withdrawal nor, 
                        will it affect the processing of your personal information conducted in reliance on lawful processing grounds 
                        other than consent.
                    </p>

                    <p className="text-gray-600 dark:text-gray-400 mt-6">
                        <strong>Opting out of marketing and promotional communications:</strong> You can unsubscribe from our marketing and 
                        promotional communications at any time by clicking the unsubscribe link in the emails that we send, or by contacting 
                        us using the details provided in the section 
                        <a href="#contact" className="text-text dark:text-text-dark ml-1">
                            'How can you contact us about this policy?'
                        </a> below. You will then be removed from the marketing lists. However, we may still communicate with you - for example, 
                        to send you service-related messages that are necessary for the administration and use of your account, to respond to 
                        service requests, or for other non-marketing purposes.
                    </p>


                    <p className="text-gray-600 dark:text-gray-400 mt-6">
                        <strong>Account Information</strong>
                    </p>

                    <p className="text-gray-600 dark:text-gray-400 mt-6">
                        If you would at any time like to review or change the information in your account or terminate your account, you can:
                    </p>
                    <ul className="list-disc pl-6 space-y-2 text-gray-600 dark:text-gray-400">
                        <li>Log in to your account settings and update your user account.</li>
                        <li>Contact us using the contact information provided.</li>
                    </ul>
                    <p className="text-gray-600 dark:text-gray-400">
                        Upon your request to terminate your account, we will deactivate or delete your account and information from 
                        our active databases. However, we may retain some information in our files to prevent fraud, troubleshoot 
                        problems, assist with any investigations, enforce our legal terms and/or comply with applicable legal requirements.
                    </p>

                    <p className="text-gray-600 dark:text-gray-400 mt-6">
                        <strong>Cookies and similar technologies:</strong> Most Web browsers are set to accept cookies by default. If you prefer, 
                        you can usually choose to set your browser to remove cookies and reject cookies. If you choose to remove cookies or reject 
                        cookies, this could affect certain features or services of our Services. For further information, please see our 
                        <Link to="/cookies" className="text-sm text-text dark:text-text-dark ml-1">
                            Cookie Policy
                        </Link>.
                    </p>

                    <p className="text-gray-600 dark:text-gray-400">
                        If you have questions or comments about your privacy rights, you may email us at 
                        <a href="mailto:privacy@ask-elly.co" className="text-text dark:text-text-dark ml-1">
                            privacy@ask-elly.co
                        </a>.
                    </p>
                </div>
            </section>

            <section className="mb-8" id="DNT">
                <h2 className="text-xl font-semibold text-gray-900 dark:text-gray-100 mb-4">
                    12. Controls for Do-Not-Track features
                </h2>
                <div className="space-y-4">
                    <p className="text-gray-600 dark:text-gray-400">
                        Most web browsers and some mobile operating systems and mobile applications include a Do-Not-Track ('DNT') 
                        feature or setting you can activate to signal your privacy preference not to have data about your online 
                        browsing activities monitored and collected. At this stage, no uniform technology standard for recognizing 
                        and implementing DNT signals has been finalized. As such, we do not currently respond to DNT browser signals 
                        or any other mechanism that automatically communicates your choice not to be tracked online. If a standard 
                        for online tracking is adopted that we must follow in the future, we will inform you about that practice 
                        in a revised version of this Privacy Policy.
                    </p>
                    <p className="text-gray-600 dark:text-gray-400">
                        California law requires us to let you know how we respond to web browser DNT signals. Because there currently 
                        is not an industry standard for recognising or honouring DNT signals, we do not respond to them at this time.
                    </p>
                </div>
            </section>

            <section className="mb-8" id="usprivacy">
                <h2 className="text-xl font-semibold text-gray-900 dark:text-gray-100 mb-4">
                    13. Do United States residents have specific privacy rights?
                </h2>
                <div className="space-y-4">
                    <p className="text-gray-600 dark:text-gray-400">
                        <em><strong>In Short: </strong>If you are a resident of California, Colorado, Connecticut, Delaware, Florida, Indiana, Iowa, Kentucky, Minnesota, 
                        Montana, Nebraska, New Hampshire, New Jersey, Oregon, Tennessee, Texas, Utah, or Virginia, you may have the right to request access to and receive 
                        details about the personal information we maintain about you and how we have processed it, correct inaccuracies, get a copy of, or delete your 
                        personal information. You may also have the right to withdraw your consent to our processing of your personal information. These rights may be 
                        limited in some circumstances by applicable law. More information is provided below.</em>
                    </p>

                    <div>
                        <h3 className="text-lg font-semibold text-gray-900 dark:text-gray-100 mb-2">Categories of Personal Information We Collect</h3>
                        <p className="text-gray-600 dark:text-gray-400 mb-4">
                            The table below shows the categories of personal information we have collected in the past twelve (12) months. 
                            Note that a 'YES' in the 'Collected' column means that we have collected information within that category - NOT that we have collected every item 
                            listed under that category. Please do not hesitate to contact us if you have any questions about the information we collect.
                        </p>
                        <div className="overflow-x-auto">
                            <table className="min-w-full border-collapse border border-gray-300 dark:border-gray-700">
                                <thead>
                                    <tr className="bg-gray-100 dark:bg-gray-800">
                                        <th className="border border-gray-300 dark:border-gray-700 p-2 text-left text-gray-900 dark:text-gray-100">Category</th>
                                        <th className="border border-gray-300 dark:border-gray-700 p-2 text-left text-gray-900 dark:text-gray-100">Examples</th>
                                        <th className="border border-gray-300 dark:border-gray-700 p-2 text-center text-gray-900 dark:text-gray-100">Collected</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td className="border border-gray-300 dark:border-gray-700 p-2 text-gray-600 dark:text-gray-400">A. Identifiers</td>
                                        <td className="border border-gray-300 dark:border-gray-700 p-2 text-gray-600 dark:text-gray-400">Contact details, such as real name, alias, postal address, telephone or mobile contact number, unique personal identifier, online identifier, Internet Protocol address, email address, and account name</td>
                                        <td className="border border-gray-300 dark:border-gray-700 p-2 text-center text-gray-600 dark:text-gray-400">YES</td>
                                    </tr>
                                    <tr>
                                        <td className="border border-gray-300 dark:border-gray-700 p-2 text-gray-600 dark:text-gray-400">B. Personal information as defined in the California Customer Records statute</td>
                                        <td className="border border-gray-300 dark:border-gray-700 p-2 text-gray-600 dark:text-gray-400">Name, contact information, education, employment, employment history, and financial information</td>
                                        <td className="border border-gray-300 dark:border-gray-700 p-2 text-center text-gray-600 dark:text-gray-400">YES</td>
                                    </tr>
                                    <tr>
                                        <td className="border border-gray-300 dark:border-gray-700 p-2 text-gray-600 dark:text-gray-400">C. Protected classification characteristics under state or federal law</td>
                                        <td className="border border-gray-300 dark:border-gray-700 p-2 text-gray-600 dark:text-gray-400">Gender, age, date of birth, race and ethnicity, national origin, marital status, and other demographic data</td>
                                        <td className="border border-gray-300 dark:border-gray-700 p-2 text-center text-gray-600 dark:text-gray-400">NO</td>
                                    </tr>
                                    <tr>
                                        <td className="border border-gray-300 dark:border-gray-700 p-2 text-gray-600 dark:text-gray-400">D. Commercial information</td>
                                        <td className="border border-gray-300 dark:border-gray-700 p-2 text-gray-600 dark:text-gray-400">Transaction information, purchase history, financial details, and payment information</td>
                                        <td className="border border-gray-300 dark:border-gray-700 p-2 text-center text-gray-600 dark:text-gray-400">NO</td>
                                    </tr>
                                    <tr>
                                        <td className="border border-gray-300 dark:border-gray-700 p-2 text-gray-600 dark:text-gray-400">E. Biometric information</td>
                                        <td className="border border-gray-300 dark:border-gray-700 p-2 text-gray-600 dark:text-gray-400">Fingerprints and voiceprints</td>
                                        <td className="border border-gray-300 dark:border-gray-700 p-2 text-center text-gray-600 dark:text-gray-400">NO</td>
                                    </tr>
                                    <tr>
                                        <td className="border border-gray-300 dark:border-gray-700 p-2 text-gray-600 dark:text-gray-400">F. Internet or similar network activity</td>
                                        <td className="border border-gray-300 dark:border-gray-700 p-2 text-gray-600 dark:text-gray-400">Browsing history, search history, online behavior, interest data, and interactions with our and other websites, applications, systems, and advertisements</td>
                                        <td className="border border-gray-300 dark:border-gray-700 p-2 text-center text-gray-600 dark:text-gray-400">YES</td>
                                    </tr>
                                    <tr>
                                        <td className="border border-gray-300 dark:border-gray-700 p-2 text-gray-600 dark:text-gray-400">G. Geolocation data</td>
                                        <td className="border border-gray-300 dark:border-gray-700 p-2 text-gray-600 dark:text-gray-400">Device location</td>
                                        <td className="border border-gray-300 dark:border-gray-700 p-2 text-center text-gray-600 dark:text-gray-400">NO</td>
                                    </tr>
                                    <tr>
                                        <td className="border border-gray-300 dark:border-gray-700 p-2 text-gray-600 dark:text-gray-400">H. Audio, electronic, visual information</td>
                                        <td className="border border-gray-300 dark:border-gray-700 p-2 text-gray-600 dark:text-gray-400">Images and audio, video or call recordings created in connection with our business activities</td>
                                        <td className="border border-gray-300 dark:border-gray-700 p-2 text-center text-gray-600 dark:text-gray-400">YES</td>
                                    </tr>
                                    <tr>
                                        <td className="border border-gray-300 dark:border-gray-700 p-2 text-gray-600 dark:text-gray-400">I. Professional or employment-related information</td>
                                        <td className="border border-gray-300 dark:border-gray-700 p-2 text-gray-600 dark:text-gray-400">Business contact details in order to provide you our Services at a business level or job title, work history, and professional qualifications if you apply for a job with us</td>
                                        <td className="border border-gray-300 dark:border-gray-700 p-2 text-center text-gray-600 dark:text-gray-400">NO</td>
                                    </tr>
                                    <tr>
                                        <td className="border border-gray-300 dark:border-gray-700 p-2 text-gray-600 dark:text-gray-400">J. Education Information</td>
                                        <td className="border border-gray-300 dark:border-gray-700 p-2 text-gray-600 dark:text-gray-400">Student records and directory information</td>
                                        <td className="border border-gray-300 dark:border-gray-700 p-2 text-center text-gray-600 dark:text-gray-400">NO</td>
                                    </tr>
                                    <tr>
                                        <td className="border border-gray-300 dark:border-gray-700 p-2 text-gray-600 dark:text-gray-400">K. Inferences drawn from collected personal information</td>
                                        <td className="border border-gray-300 dark:border-gray-700 p-2 text-gray-600 dark:text-gray-400">Inferences drawn from any of the collected personal information listed above to create a profile or summary about, for example, an individual's preferences and characteristics</td>
                                        <td className="border border-gray-300 dark:border-gray-700 p-2 text-center text-gray-600 dark:text-gray-400">NO</td>
                                    </tr>
                                    <tr>
                                        <td className="border border-gray-300 dark:border-gray-700 p-2 text-gray-600 dark:text-gray-400">L. Sensitive personal Information</td>
                                        <td className="border border-gray-300 dark:border-gray-700 p-2 text-gray-600 dark:text-gray-400"></td>
                                        <td className="border border-gray-300 dark:border-gray-700 p-2 text-center text-gray-600 dark:text-gray-400">NO</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>

                    <p className="text-gray-600 dark:text-gray-400">
                        We may also collect other personal information outside of these categories through instances where you interact with us in person, online, or by phone 
                        or mail in the context of:
                    </p>
                    <ul className="list-disc pl-6 space-y-2 text-gray-600 dark:text-gray-400">
                        <li>Receiving help through our customer support channels;</li>
                        <li>Participation in customer surveys or contests; and</li>
                        <li>Facilitation in the delivery of our Services and to respond to your inquiries.</li>
                    </ul>

                    <p className="text-gray-600 dark:text-gray-400">
                        We will use and retain the collected personal information as needed to provide the Services or for:
                    </p>
                    <ul className="list-disc pl-6 space-y-2 text-gray-600 dark:text-gray-400">
                        <li>Category A - As long as the user has an account with us + 3 months</li>
                        <li>Category B - As long as the user has an account with us + 3 months</li>
                        <li>Category F - As long as the user has an account with us + 3 months</li>
                        <li>Category H - As long as the user has an account with us + 3 months</li>
                    </ul>

                    <h3 className="text-lg font-semibold text-gray-900 dark:text-gray-100 mb-2">Sources of Personal Information</h3>
                    <p className="text-gray-600 dark:text-gray-400">
                        Learn more about the sources of personal information we collect in 
                        <a href="#infocollect" className="text-text dark:text-text-dark ml-1">"What information do we collect?"</a>
                    </p>

                    <h3 className="text-lg font-semibold text-gray-900 dark:text-gray-100 mb-2">How We Use and Share Personal Information</h3>
                    <p className="text-gray-600 dark:text-gray-400">
                        Learn more about how we use your personal information in the section 
                        <a href="#infouse" className="text-text dark:text-text-dark ml-1">"How do we process your information?"</a>
                    </p>

                    <h3 className="text-lg font-semibold text-gray-900 dark:text-gray-100 mb-2">Will your information be shared with anyone else?</h3>
                    <p className="text-gray-600 dark:text-gray-400">
                        We may disclose your personal information with our service providers. Learn more about how we disclose personal information to in the section 
                        <a href="#whoshare" className="text-text dark:text-text-dark ml-1">"When and with whom do we share your personal information?"</a>
                    </p>

                    <p className="text-gray-600 dark:text-gray-400">
                        We may use your personal information for our own business purposes, such as for undertaking internal research for technological development 
                        and demonstration. This is not considered to be 'selling' of your personal information.
                    </p>

                    <p className="text-gray-600 dark:text-gray-400">
                        We have not sold or shared any personal information to third parties for a business or commercial purpose in the preceding twelve (12) months. 
                        We have disclosed the following categories of personal information to third parties for a business or commercial purpose in the preceding twelve 
                        (12) months:
                    </p>

                    <ul className="list-disc pl-6 space-y-2 text-gray-600 dark:text-gray-400">
                        <li>Category A. Identifiers</li>
                        <li>Category B. Personal information as defined in the California Customer Records law</li>
                        <li>Category F. Internet or other electronic network activity information</li>
                        <li>Category H. Audio, electronic, visual, and similar information</li>
                    </ul>

                    <p className="text-gray-600 dark:text-gray-400">
                        The categories of third parties to whom we disclosed personal information for a business or commercial purpose can be found under 
                        <a href="#whoshare" className="text-text dark:text-text-dark ml-1">"When and with whom do we share your personal information?"</a>
                    </p>

                    <h3 className="text-lg font-semibold text-gray-900 dark:text-gray-100 mb-2">Your Rights</h3>
                    <p className="text-gray-600 dark:text-gray-400">
                        You have rights under certain US state data protection laws. However, these rights are not absolute, and in certain cases, we may decline your 
                        request as permitted by law. These rights include:
                    </p>

                    <ul className="list-disc pl-6 space-y-2 text-gray-600 dark:text-gray-400">
                        <li><strong>Right to know</strong> whether or not we are processing your personal data</li>
                        <li><strong>Right to access</strong> your personal data</li>
                        <li><strong>Right to correct</strong> inaccuracies in your personal data</li>
                        <li><strong>Right to request</strong> the deletion of your personal data</li>
                        <li><strong>Right to obtain a copy</strong> of the personal data you previously shared with us</li>
                        <li><strong>Right to non-discrimination</strong> for exercising your rights</li>
                        <li><strong>Right to opt out</strong> of the processing of your personal data if it is used for targeted advertising, the sale of personal data, 
                        or profiling in furtherance of decisions that produce legal or similarly significant effects</li>
                    </ul>

                    <p className="text-gray-600 dark:text-gray-400">
                        Depending upon the state where you live, you may also have the following rights:
                    </p>

                    <ul className="list-disc pl-6 space-y-2 text-gray-600 dark:text-gray-400">
                        <li>Right to access the categories of personal data being processed (as permitted by applicable law, including Minnesota's privacy law)</li>
                        <li>Right to obtain a list of the categories of third parties to which we have disclosed personal data (as permitted by applicable law, including California's and Delaware's privacy law)</li>
                        <li>Right to obtain a list of specific third parties to which we have disclosed personal data (as permitted by applicable law, including Minnesota's and Oregon's privacy law)</li>
                        <li>Right to review, understand, question, and correct how personal data has been profiled (as permitted by applicable law, including Minnesota's privacy law)</li>
                        <li>Right to limit use and disclosure of sensitive personal data (as permitted by applicable law, including California's privacy law)</li>
                        <li>Right to opt out of the collection of sensitive data and personal data collected through the operation of a voice or facial recognition feature (as permitted by applicable law, including Florida's privacy law)</li>
                    </ul>

                    <h3 className="text-lg font-semibold text-gray-900 dark:text-gray-100 mb-2">How to Exercise Your Rights</h3>
                    <p className="text-gray-600 dark:text-gray-400">
                        To exercise these rights, you can contact us by emailing us at{' '}
                        <a href="mailto:privacy@ask-elly.co" className="text-text dark:text-text-dark">privacy@ask-elly.co</a>, 
                        by mailing our corporate address, or by referring to the contact details at the bottom of this document.
                    </p>

                    <p className="text-gray-600 dark:text-gray-400">
                        Under certain US state data protection laws, you can designate an authorised agent to make a request on your behalf. 
                        We may deny a request from an authorised agent that does not submit proof that they have been validly authorised to act on your behalf in 
                        accordance with applicable laws.
                    </p>

                    <h3 className="text-lg font-semibold text-gray-900 dark:text-gray-100 mb-2">Request Verification</h3>
                    <p className="text-gray-600 dark:text-gray-400">
                        Upon receiving your request, we will need to verify your identity to determine you are the same person about whom we have the information 
                        in our system. We will only use personal information provided in your request to verify your identity or authority to make the request.
                        However, if we cannot verify your identity from the information already maintained by us, we may request that you provide additional information 
                        for the purposes of verifying your identity and for security or fraud-prevention purposes.
                    </p>

                    <p className="text-gray-600 dark:text-gray-400">
                        If you submit the request through an authorised agent, we may need to collect additional information to verify your identity before processing 
                        your request and the agent will need to provide a written and signed permission from you to submit such request on your behalf.
                    </p>

                    <h3 className="text-lg font-semibold text-gray-900 dark:text-gray-100 mb-2">Appeals</h3>
                    <p className="text-gray-600 dark:text-gray-400">
                        Under certain US state data protection laws, if we decline to take action regarding your request, you may appeal our decision by emailing us at{' '}
                        <a href="mailto:privacy@ask-elly.co" className="text-text dark:text-text-dark">privacy@ask-elly.co</a>. 
                        We will inform you in writing of any action taken or not taken in response to the appeal, including a written explanation of the reasons for 
                        the decisions. If your appeal is denied, you may submit a complaint to your state attorney general.
                    </p>

                    <h3 className="text-lg font-semibold text-gray-900 dark:text-gray-100 mb-2">California 'Shine The Light' Law</h3>
                    <p className="text-gray-600 dark:text-gray-400">
                        California Civil Code Section 1798.83, also known as the 'Shine The Light' law, permits our users who are California residents to request and obtain from us, 
                        once a year and free of charge, information about categories of personal information (if any) we disclosed to third parties for direct marketing purposes and 
                        the names and addresses of all third parties with which we shared personal information in the immediately preceding calendar year. If you are a California resident 
                        and would like to make such a request, please submit your request in writing to us using the contact information provided in the section{' '}
                        <a href="#contact" className="text-text dark:text-text-dark ml-1">"How can you contact us about this policy?"</a>
                    </p>
                </div>
            </section>

            <section className="mb-8" id="policyupdates">
                <h2 className="text-xl font-semibold text-gray-900 dark:text-gray-100 mb-4">
                    14. Do we make updates to this policy?
                </h2>
                <div className="space-y-4">
                    <p className="text-gray-600 dark:text-gray-400">
                        <em><strong>In Short: </strong>Yes, we will update this policy as necessary to stay compliant with relevant laws.</em>
                    </p>
                    <p className="text-gray-600 dark:text-gray-400">
                        We may update this Privacy Policy from time to time. The updated version will be indicated by an updated 
                        'Revised' date at the top of this Privacy Policy. If we make material changes to this Privacy Policy, 
                        we may notify you either by prominently posting a notice of such changes or by directly sending you a 
                        notification. We encourage you to review this Privacy Policy frequently to be informed of how we are 
                        protecting your information.
                    </p>
                </div>
            </section>

            <section className="mb-8" id="contact">
                <h2 className="text-xl font-semibold text-gray-900 dark:text-gray-100 mb-4">
                    15. How can you contact us about this policy?
                </h2>
                <div className="space-y-4">
                    <p className="text-gray-600 dark:text-gray-400">
                        If you have questions or comments about this policy, you may email us at 
                        <a href="mailto:privacy@ask-elly.co" className="text-text dark:text-text-dark ml-1">
                            privacy@ask-elly.co
                        </a> or contact us by post at:
                    </p>
                    <div className="text-gray-600 dark:text-gray-400">
                    <p>
                        Elly ApS<br />
                        Porcelænshaven 4D, 1. tv<br />
                        Frederiksberg 2000<br />
                        Denmark
                    </p>
                    </div>
                </div>
            </section>

            <section className="mb-8" id="request">
                <h2 className="text-xl font-semibold text-gray-900 dark:text-gray-100 mb-4">
                    16. How can you review, update, or delete the data we collect from you?
                </h2>
                <div className="space-y-4">
                    <p className="text-gray-600 dark:text-gray-400">
                        You have the right to request access to the personal infromation we collect from you, details about 
                        how we have processed it, correct inaccuracies, or delete your personal information. You may also have 
                        the right to withdraw your consent to our processing of your personal infromation. These rights may be 
                        limited in some circumstances by applicable law. To request to review, update, or delete your personal 
                        information, please contact us at 
                        <a href="mailto:privacy@ask-elly.co" className="text-text dark:text-text-dark ml-1">
                            privacy@ask-elly.co
                        </a>.
                    </p>
                </div>
            </section>
        </div>
    </div>
  );
};

export default PrivacyPolicy;