import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { countries } from 'countries-list';
import { toast } from 'react-toastify';
import { API_URL } from '../config/api';
import simonImage from '../assets/simon.png';

export default function WaitlistForm() {
  const [agreed, setAgreed] = useState(false);
  const [emailTouched, setEmailTouched] = useState(false);
  const [formData, setFormData] = useState({
      name: '',
      email: '',
      confirmEmail: '',
      country: '',
      occupation: '',
      otherOccupation: '',
      referralSource: ''
  });
  const [submitted, setSubmitted] = useState(false);

  const isValidEmail = (email) => {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return emailRegex.test(email);
  };
  
  // Add this helper function
  const isEmailInvalid = () => {
      return emailTouched && formData.email.trim() !== '' && !isValidEmail(formData.email);
  };

  const doEmailsMatch = () => {
    return formData.email === formData.confirmEmail;
  };

  // Add this function to check if all fields are filled
  const isFormValid = () => {
      return (
          formData.name.trim() !== '' &&
          formData.email.trim() !== '' &&
          formData.confirmEmail.trim() !== '' &&
          isValidEmail(formData.email) &&
          doEmailsMatch() &&
          formData.country !== '' &&
          formData.occupation !== '' &&
          (formData.occupation !== 'other' || formData.otherOccupation.trim() !== '') &&
          formData.referralSource !== '' &&
          agreed
      );
  };

  const countriesList = Object.entries(countries)
      .map(([code, country]) => ({
          code,
          name: country.name
      }))
      .sort((a, b) => a.name.localeCompare(b.name));

        
  const occupations = [
    { value: 'student', label: 'Student' },  
    { value: 'founder', label: 'Founder / Co-founder' },
    { value: 'researcher', label: 'Researcher / Academic' },
    { value: 'investor', label: 'Investor' },
    { value: 'developer', label: 'Software Developer / Engineer' },
    { value: 'designer', label: 'Designer' },
    { value: 'marketing', label: 'Marketing / Sales' },
    { value: 'consultant', label: 'Consultant' },
    { value: 'other', label: 'Other' }
  ];

  const referralSources = [
    { value: 'team', label: 'Elly team' },
    { value: 'friend', label: 'Friend, Family or Colleague' },
    { value: 'social_media', label: 'Social Media' },
    { value: 'other_post', label: 'Other post/article, e.g., Reddit' },
    { value: 'search_engine', label: 'Search Engine' },
    { value: 'other', label: 'Other' }
  ];

  const handleSubmit = async (e) => {
      e.preventDefault();
      
      try {
          const response = await fetch(`${API_URL}/waitlist/submit`, {
              method: 'POST',
              headers: {
                  'Content-Type': 'application/json',
              },
              body: JSON.stringify(formData)
          });

          const data = await response.json();
  
          if (!response.ok) {
              if (response.status === 400 && data.errors) {
                  // Handle validation errors
                  const errorMessage = data.errors
                      .map(err => err.msg)
                      .join('. ');
                  toast.error(errorMessage);
                  return;
              }
              if (response.status === 429) {
                  // Rate limit exceeded
                  toast.error('Too many attempts. Please try again later.');
                  return;
              }
              throw new Error('Failed to submit');
          }
  
          // Clear form
          setFormData({
              name: '',
              email: '',
              confirmEmail: '',
              country: '',
              occupation: '',
              otherOccupation: '',
              referralSource: ''
          });
          setAgreed(false);
          setEmailTouched(false);
          setSubmitted(true);
          
      } catch (error) {
          console.error('Error submitting form:', error);
          toast.error('Sorry, there was an error submitting your request. Please try again.');
      }
  };

  const handleChange = (e) => {
      const { name, value } = e.target;
      setFormData(prev => ({
          ...prev,
          [name]: value
      }));
  };

  return (
    <div className="flex-1 flex items-center justify-center bg-white dark:bg-secondary-dark">
        <div className="w-full px-6 py-24 sm:py-32 lg:px-8">
            {!submitted && (
                <div className="mx-auto max-w-xl text-center">
                    <h2 className="text-base/7 font-semibold text-primary">Join the journey</h2>
                    <h2 className="text-balance text-4xl font-semibold tracking-tight text-text dark:text-text-dark sm:text-5xl">Get early access to Elly</h2>
                    <p className="mt-6 text-lg/8 text-gray-600 dark:text-text-dark/80">Join the waitlist and we'll give you access as soon as possible</p>
                </div>
            )}
            {submitted ? (
                <div className="mx-auto max-w-xl text-center">
                    <h2 className="text-3xl sm:text-4xl font-semibold text-text dark:text-text-dark">You're on the list! 🎉</h2>
                    <p className="mt-4 text-sm md:text-lg text-gray-600 dark:text-text-dark/80">
                        Thank you for joining the waitlist. It really means a lot to us. 
                    </p>
                    <p className="mt-4 text-sm md:text-lg text-gray-600 dark:text-text-dark/80">
                        We'll get your account set up as soon as possible and send you an email with a link to get started (nearly always within 24 hours and often much faster). <br /> 
                    </p>
                    <p className="mt-4 text-sm md:text-lg text-gray-600 dark:text-text-dark/80">
                        Please don't hesitate to get in touch on <Link to="mailto:simon@ask-elly.co" className="text-primary hover:text-primary/90">simon@ask-elly.co</Link> or <Link to="https://www.linkedin.com/in/simon-riggelsen/" target="_blank" rel="noopener noreferrer" className="text-primary hover:text-primary/90">LinkedIn</Link>.
                    </p>
                    <div className="mt-4 flex items-center justify-center gap-4">
                        <img 
                            src={simonImage} 
                            alt="Simon" 
                            className="h-16 w-16 md:h-20 md:w-20 rounded-full object-cover border border-primary dark:border-primary shadow-lg"
                        />
                        <div className="text-left text-gray-600 dark:text-text-dark/80">
                            <p className="text-sm md:text-lg font-medium">
                                Simon
                            </p>
                            <em className="text-xs md:text-sm">
                                Co-founder of Elly
                            </em>
                        </div>
                    </div>

                    <div className="mt-6 flex flex-col sm:flex-row gap-4 justify-center items-center">
                        <Link to="/" className="text-gray-600 dark:text-text-dark w-1/2 sm:w-1/3 hover:bg-primary hover:text-white dark:hover:bg-primary dark:hover:text-white transition-colors duration-200 text-center text-sm font-semibold border border-border dark:border-border-dark p-3 rounded-lg">
                            Homepage
                        </Link>
                        <button 
                            onClick={() => setSubmitted(false)} 
                            className="text-gray-600 dark:text-text-dark w-1/2 sm:w-1/3 hover:bg-primary hover:text-white dark:hover:bg-primary dark:hover:text-white transition-colors duration-200 text-center text-sm font-semibold border border-border dark:border-border-dark p-3 rounded-lg"
                        >
                            Fill in form again
                        </button>
                    </div>
                    
                </div>
            ) : (
                <form onSubmit={handleSubmit} className="mx-auto mt-10 max-w-xl sm:mt-8">
                    <div className="grid grid-cols-1 gap-x-8 gap-y-6">
                    <div>
                      <label htmlFor="name" className="block text-sm/6 font-semibold text-text dark:text-text-dark">
                        Full Name
                      </label>
                      <div className="mt-2.5">
                        <input
                          id="name"
                          name="name"
                          type="text"
                          autoComplete="name"
                          required
                          value={formData.name}
                          onChange={handleChange}
                          className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 dark:text-text-dark dark:bg-background-dark shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-gray-500 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary text-sm/6"
                        />
                      </div>
                    </div>

                    <div>
                      <label htmlFor="email" className="block text-sm/6 font-semibold text-text dark:text-text-dark">
                          Email
                      </label>
                      <div className="mt-2.5">
                          <input
                              id="email"
                              name="email"
                              type="email"
                              autoComplete="email"
                              required
                              pattern="[^\s@]+@[^\s@]+\.[^\s@]+"
                              value={formData.email}
                              onChange={handleChange}
                              onBlur={() => setEmailTouched(true)}
                              className={`block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 dark:text-text-dark dark:bg-background-dark shadow-sm ring-1 ring-inset ${
                                  isEmailInvalid() 
                                      ? 'ring-red-500 focus:ring-red-500' 
                                      : 'ring-gray-300 dark:ring-gray-500 focus:ring-primary'
                              } placeholder:text-gray-400 focus:ring-2 focus:ring-inset text-sm/6`}
                          />
                          {isEmailInvalid() && (
                              <p className="mt-2 text-sm text-red-600">
                                  Please enter a valid email address
                              </p>
                          )}
                      </div>
                    </div>

                    <div>
                      <label htmlFor="confirmEmail" className="block text-sm/6 font-semibold text-text dark:text-text-dark">
                          Confirm Email
                      </label>
                      <div className="mt-2.5">
                          <input
                              id="confirmEmail"
                              name="confirmEmail"
                              type="email"
                              required
                              value={formData.confirmEmail}
                              onChange={handleChange}
                              className={`block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 dark:text-text-dark dark:bg-background-dark shadow-sm ring-1 ring-inset ${
                                  formData.confirmEmail && !doEmailsMatch()
                                      ? 'ring-red-500 focus:ring-red-500' 
                                      : 'ring-gray-300 dark:ring-gray-500 focus:ring-primary'
                              } placeholder:text-gray-400 focus:ring-2 focus:ring-inset text-sm/6`}
                          />
                          {formData.confirmEmail && !doEmailsMatch() && (
                              <p className="mt-2 text-sm text-red-600">
                                  Emails do not match
                              </p>
                          )}
                      </div>
                    </div>

                    <div>
                      <label htmlFor="country" className="block text-sm/6 font-semibold text-text dark:text-text-dark">
                        Country
                      </label>
                      <div className="mt-2.5">
                        <select
                          id="country"
                          name="country"
                          required
                          value={formData.country}
                          onChange={handleChange}
                          className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 dark:text-text-dark dark:bg-background-dark shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-gray-500 focus:ring-2 focus:ring-inset focus:ring-primary text-sm/6"
                        >
                          <option value="">Select a country</option>
                          {countriesList.map(({ code, name }) => (
                              <option key={code} value={code}>
                              {name}
                              </option>
                          ))}
                        </select>
                      </div>
                    </div>

                    <div>
                      <label htmlFor="occupation" className="block text-sm/6 font-semibold text-text dark:text-text-dark">
                          Occupation
                      </label>
                      <div className="mt-2.5">
                          <select
                          id="occupation"
                          name="occupation"
                          required
                          value={formData.occupation}
                          onChange={handleChange}
                          className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 dark:text-text-dark dark:bg-background-dark shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-gray-500 focus:ring-2 focus:ring-inset focus:ring-primary text-sm/6"
                          >
                          <option value="">Select your occupation</option>
                          {occupations.map(({ value, label }) => (
                              <option key={value} value={value}>
                              {label}
                              </option>
                          ))}
                          </select>
                      </div>
                    </div>
                    {formData.occupation === 'other' && (
                      <div>
                          <label htmlFor="otherOccupation" className="block text-sm/6 font-semibold text-text dark:text-text-dark">
                          Please specify your occupation
                          </label>
                          <div className="mt-2.5">
                          <input
                              type="text"
                              name="otherOccupation"
                              id="otherOccupation"
                              required
                              value={formData.otherOccupation}
                              onChange={handleChange}
                              className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 dark:text-text-dark dark:bg-background-dark shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-gray-500 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary text-sm/6"
                          />
                          </div>
                      </div>
                    )}
                    <div>
                      <label htmlFor="referralSource" className="block text-sm/6 font-semibold text-text dark:text-text-dark">
                          How did you hear about us?
                      </label>
                      <div className="mt-2.5">
                          <select
                              id="referralSource"
                              name="referralSource"
                              required
                              value={formData.referralSource}
                              onChange={handleChange}
                              className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 dark:text-text-dark dark:bg-background-dark shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-gray-500 focus:ring-2 focus:ring-inset focus:ring-primary text-sm/6"
                          >
                              <option value="">Select an option</option>
                              {referralSources.map(({ value, label }) => (
                                  <option key={value} value={value}>
                                      {label}
                                  </option>
                              ))}
                          </select>
                      </div>
                  </div>
                  </div> 

                  <div className="flex gap-x-4 sm:col-span-2 mt-4">
                      <div className="flex h-6 items-center">
                        <button
                          type="button"
                          role="switch"
                          aria-checked={agreed}
                          onClick={() => setAgreed(!agreed)}
                          value={agreed}
                          onChange={handleChange}
                          className={`relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-primary focus:ring-offset-2 ${
                            agreed ? 'bg-primary' : 'bg-gray-200'
                          }`}
                        >
                          <span className="sr-only">Agree to policies</span>
                          <span
                            aria-hidden="true"
                            className={`pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out ${
                              agreed ? 'translate-x-5' : 'translate-x-0'
                            }`}
                          />
                        </button>
                      </div>
                      <label className="text-sm/6 text-gray-600 dark:text-text-dark/80">
                        By selecting this, you agree to our{' '}
                        <Link to="/privacy" className="font-semibold text-primary hover:text-primary/90" target="_blank" rel="noopener noreferrer">
                          privacy&nbsp;policy
                        </Link>
                        {' '}and{' '}
                        <Link to="/terms" className="font-semibold text-primary hover:text-primary/90" target="_blank" rel="noopener noreferrer">
                          terms&nbsp;of&nbsp;use
                        </Link>
                        .
                      </label>
                  </div>
                  

                  <div className="mt-6">
                    <button
                      type="submit"
                      disabled={!isFormValid()}
                      className="block w-full rounded-md bg-primary px-3.5 py-2.5 text-center text-sm font-semibold text-white shadow-sm hover:bg-primary/90 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary disabled:opacity-50 disabled:cursor-not-allowed"
                    >
                      Join waitlist
                    </button>
                  </div>
                </form>
            )}
        </div>
    </div>
  );
}