import React from 'react';
import { StarIcon, PencilIcon, TrashIcon } from '@heroicons/react/24/outline';
import { StarIcon as StarIconSolid } from '@heroicons/react/24/solid';

const ConversationOptionsPopup = ({ onStar, onEdit, onClear, isStarred }) => {
  return (
    <div className="py-1" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
      <button 
        className="flex items-center w-full px-4 py-2 text-sm text-gray-700 dark:text-gray-200 hover:bg-gray-100 dark:hover:bg-gray-600" 
        role="menuitem"
        onClick={onStar}
      >
        {isStarred ? (
          <StarIconSolid className="h-4 w-4 mr-2 text-yellow-400" />
        ) : (
          <StarIcon className="h-4 w-4 mr-2 text-yellow-400" />
        )}
        {isStarred ? 'Unstar conversation' : 'Star conversation'}
      </button>
      <button 
        className="flex items-center w-full px-4 py-2 text-sm text-gray-700 dark:text-gray-200 hover:bg-gray-100 dark:hover:bg-gray-600" 
        role="menuitem"
        onClick={onEdit}
      >
        <PencilIcon className="h-4 w-4 mr-2 text-gray-700 dark:text-gray-200" />
        Edit title
      </button>
      <button 
        className="flex items-center w-full px-4 py-2 text-sm text-gray-700 dark:text-gray-200 hover:bg-gray-100 dark:hover:bg-gray-600" 
        role="menuitem"
        onClick={onClear}
      >
        <TrashIcon className="h-4 w-4 mr-2 text-red-400" />
        Clear conversation
      </button>
    </div>
  );
};

export default ConversationOptionsPopup;