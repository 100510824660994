import React from 'react';

const Tooltip = ({ children, content, detailed = false, position = 'top', width = 'w-auto' }) => {
  const positionClasses = {
    top: 'bottom-full left-1/2 transform -translate-x-1/2 mb-2',
    right: 'left-full top-1/2 transform -translate-y-1/2 ml-2'
  };
  
  const tooltipClasses = `absolute ${positionClasses[position]} px-3 py-2 text-text dark:text-text-dark text-xs rounded-lg opacity-0 invisible 
    group-hover:opacity-100 group-hover:visible pointer-events-none bg-gray-100 dark:bg-gray-800 hidden sm:block ${width} ${
      detailed ? 'text-left' : 'text-center'
    }`;

  const renderDetailedContent = (model) => (
    <div className="text-sm">
      <div className="flex items-center mb-1">
        <img src={model.logo} alt={`${model.name} logo`} className="h-4 mr-2" />
        <span className="font-bold">{model.name}</span>
      </div>
      <p className="mb-2 text-xs">{model.description}</p>
    </div>
  );

  return (
    <div className="relative inline-block group">
      {children}
      <div className={tooltipClasses}>
        {detailed ? renderDetailedContent(content) : content}
      </div>
    </div>
  );
};

export default Tooltip;