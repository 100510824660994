import React from 'react';
import WebsiteHeader from './WebsiteHeader';
import WebsiteFooter from './WebsiteFooter';

export default function Layout({ children, darkMode, toggleDarkMode }) {
  return (
      // Add min-h-screen and flex flex-col to ensure full height
      <div className="min-h-screen flex flex-col">
          <WebsiteHeader darkMode={darkMode} toggleDarkMode={toggleDarkMode} />
          {/* Add flex-1 to make main content expand */}
          <main className="flex-1 flex flex-col bg-white dark:bg-secondary-dark">
              {children}
          </main>
          <WebsiteFooter />
      </div>
  );
}