import React, { useEffect, useMemo, useRef } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { formatMessages } from '../utils/messageUtils';
import ConversationOptionsPopup from './ConversationOptionsPopup';
import { toast } from 'react-toastify';
import { API_URL } from '../config/api';
import { StarIcon, EllipsisHorizontalIcon } from '@heroicons/react/24/outline';

const ChatHistory = ({ user, 
    setMessages, 
    setFirstMessageSent, 
    setConversations, 
    conversationId, 
    setConversationId, 
    setSelectedModel, 
    onShowSearch, 
    onEditTitle, 
    starredConversations, 
    setStarredConversations, 
    nonStarredConversations, 
    setNonStarredConversations, 
    onStarConversation,
    conversationPopup,
    setConversationPopup,
    onClearConversation
}) => {
    const { isAuthenticated } = useAuth0();
    const popupRef = useRef(null);

    useEffect(() => {
        const fetchConversations = async () => {
            if (!isAuthenticated || !user) {
                console.log('User not authenticated or user data not available');
                return;
            }
    
            try {
                const response = await fetch(`${API_URL}/conversations/${user.sub}`);

                if (response.ok) {
                    const data = await response.json();
                    const starred = data.filter(conv => conv.starred);
                    const nonStarred = data.filter(conv => !conv.starred);
                    setStarredConversations(starred);
                    setNonStarredConversations(nonStarred);
                    setConversations(data);
                } else {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
            } catch (error) {
                console.error('Error fetching conversations:', error);
                toast.error('Oops, we were unable to fetch your conversations. Please try again later.');
            }
        };
    
        fetchConversations();
    }, [isAuthenticated, user, setConversations ]);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (popupRef.current && !popupRef.current.contains(event.target)) {
                setConversationPopup(null);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const fetchMessages = async (clickedConversationId) => {
        try {
            setConversationId(clickedConversationId);

            const response = await fetch(`${API_URL}/messages/${clickedConversationId}`);

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            const data = await response.json();
            const formattedMessages = formatMessages(data);
            setMessages(formattedMessages);

            setSelectedModel(formattedMessages[formattedMessages.length - 1].model);
            setFirstMessageSent(true);
        } catch (error) {
            console.error('Error fetching conversation messages:', error);
        }
    };

    const groupedConversations = useMemo(() => {
        const now = new Date();
        const today = now.setHours(0, 0, 0, 0);
        const weekAgo = new Date(today - 7 * 24 * 60 * 60 * 1000);

        return nonStarredConversations.reduce((acc, conv) => {
            const convDate = new Date(conv.updated_at);
            let key;
            if (convDate >= today) {
                key = 'Today';
            } else if (convDate >= weekAgo) {
                key = 'Last 7 Days';
            } else {
                key = 'Older';
            }
            (acc[key] = acc[key] || []).push(conv);
            return acc;
        }, {});
    }, [nonStarredConversations]);
    
    const renderConversationList = (conversations, sectionTitle = null) => (
        <div>
            {sectionTitle && (
                <h4 className="text-xs font-semibold text-gray-500 dark:text-gray-400 mt-2 mb-2 border-b border-gray-200 dark:border-gray-800 pb-1 flex items-center">
                {sectionTitle === 'Starred' && (
                    <StarIcon className="h-4 w-4 mr-1 mb-0.5 text-text dark:text-text-dark" />
                )}
                {sectionTitle}
                </h4>
            )}
            <ul className="space-y-1 mb-4">
                {conversations.map((conversation) => (
                    <li key={conversation.conversation_id} className="relative group">
                        <button 
                            className={`w-full text-left px-2 py-2 rounded-lg transition-colors duration-200 group-hover:bg-white dark:group-hover:bg-gray-700 ${
                                conversation.conversation_id === conversationId
                        ? 'bg-white dark:bg-gray-700'
                                : 'bg-secondary dark:bg-secondary-dark'
                            }`}
                            onClick={() => fetchMessages(conversation.conversation_id)}
                        >
                            <p className="text-xs text-text dark:text-text-dark truncate group-hover:pr-8 transition-all duration-200">
                                {conversation.decrypted_title}
                            </p>
                        </button>
                        <button
                            className="absolute right-2 top-1/2 transform -translate-y-1/2 p-1 rounded-lg transition-all duration-200 hidden group-hover:block"
                            onClick={(e) => {
                                e.stopPropagation();
                                setConversationPopup(conversationPopup === conversation.conversation_id ? null : conversation.conversation_id);
                            }}
                        >
                            <EllipsisHorizontalIcon className="h-5 w-5 text-text dark:text-text-dark hover:text-gray-600 dark:hover:text-gray-300" />
                        </button>
                        {conversationPopup === conversation.conversation_id && (
                            <div ref={popupRef} className="absolute right-0 top-0 mt-8 w-52 rounded-md shadow-lg bg-white dark:bg-gray-700 ring-1 ring-black ring-opacity-5 z-50">
                                <ConversationOptionsPopup
                                    onStar={() => onStarConversation(conversation)}
                                    onEdit={() => onEditTitle(conversation)}
                                    onClear={() => onClearConversation(conversation)}
                                    isStarred={conversation.starred}
                                />
                            </div>
                        )}
                    </li>
                ))}
            </ul>
        </div>
    );

    return (
        <div className="flex flex-col h-full w-full">
            <div className="flex justify-start space-x-2 items-center px-4 py-2">
                <h3 className="text-sm font-semibold text-text dark:text-text-dark">Your conversations</h3>
                <button
                onClick={onShowSearch}
                className="p-1.5 rounded-lg hover:bg-background dark:hover:bg-hover-dark border border-border dark:border-border-dark transition-colors duration-200"
            >
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-3 w-3" fill="none" viewBox="0 0 24 24" stroke="#7cb342">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={3} d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
                    </svg>
                </button>
            </div>
            <div className="flex-1 overflow-y-auto px-4 mb-8">
                {starredConversations.length > 0 && renderConversationList(starredConversations, 'Starred')}
                {Object.entries(groupedConversations).map(([section, convs]) => 
                    convs.length > 0 && (
                        <React.Fragment key={section}>
                            {renderConversationList(convs, section)}
                        </React.Fragment>
                    )
                )}
            </div>
        </div>
    );
};

export default ChatHistory;