import React, { useRef, useEffect } from 'react';

const SourcesPopup = ({ sources, onClose }) => {
    const popupRef = useRef(null);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (popupRef.current && !popupRef.current.contains(event.target)) {
                onClose();
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => document.removeEventListener('mousedown', handleClickOutside);
    }, [onClose]);

    return (
        <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-10 backdrop-blur-sm p-4">
            <div ref={popupRef} className="w-full md:w-3/4 lg:w-3/4 h-5/6 max-w-3xl flex flex-col bg-white dark:bg-secondary-dark rounded-lg shadow-lg border border-border dark:border-border-dark">
                <div className="flex-shrink-0 px-4 sm:px-6 pt-4 pb-2 border-b border-border dark:border-border-dark">
                    <h2 className="text-lg font-semibold text-text dark:text-text-dark">Sources</h2>
                </div>
                <div className="flex-grow overflow-y-auto px-6 py-2">
                    {sources.map((source, index) => (
                        <a
                            key={index}
                            href={source.metadata.url}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="flex items-center gap-3 p-3 mb-2 rounded-lg hover:bg-hover dark:hover:bg-hover-dark transition-colors duration-200 !no-underline w-full"
                        >
                            <span className="flex-shrink-0 w-6 h-6 flex items-center justify-center bg-gray-200 dark:bg-gray-700 rounded-full text-sm">
                                {index + 1}
                            </span>
                            <div className="flex flex-col min-w-0 flex-1">
                                <div className="text-sm font-medium text-text dark:text-text-dark truncate">
                                    {source.metadata.title}
                                </div>
                                <div className="text-xs text-gray-500 dark:text-gray-400 truncate">
                                    {source.metadata.url}
                                </div>
                            </div>
                        </a>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default SourcesPopup;